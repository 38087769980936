import { NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { AmoTrashCanModule } from 'src/app/htmlElements/amo/trash-can/amo-trash-can.module';
import { CheckboxModule } from 'src/app/htmlElements/checkbox/checkbox.module';
import { LanguageModule } from 'src/app/translate/language.module';
import { AmoTableStopEventImageComponent } from '../images/amo-table-stop-event-image/amo-table-stop-event-image.component';
import { AmoTableRestartEventImageComponent } from '../images/amo-table-restart-event-image/amo-table-restart-event-image.component';
import { AMO_TABLE_EVENT_RESTART_PROCESS, AMO_TABLE_EVENT_STOP_PROCESS, AMO_TABLE_EVENT_DELETE, TAmoTableEvents } from '../models/amo-table.types';
import { AmoTableService } from '../services/amo-table.service';

@Component({
    selector: 'amo-table-events-row',
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [CheckboxModule, AmoTrashCanModule, NgIf, LanguageModule, AmoTableStopEventImageComponent, AmoTableRestartEventImageComponent, NgFor],
    templateUrl: './events-row.component.html',
    styleUrl: './events-row.component.scss'
})
export class EventsRowComponent {
    public AMO_TABLE_EVENT_RESTART_PROCESS: TAmoTableEvents = AMO_TABLE_EVENT_RESTART_PROCESS;
    public AMO_TABLE_EVENT_STOP_PROCESS: TAmoTableEvents = AMO_TABLE_EVENT_STOP_PROCESS;
    public AMO_TABLE_EVENT_DELETE: TAmoTableEvents = AMO_TABLE_EVENT_DELETE;

    @Input() events: TAmoTableEvents[] = [];
    @Input() state: boolean = false;
    @Input() totalChecked: number = 0;

    @Output() deleteEvent = new EventEmitter<null>();
    @Output() checking = new EventEmitter<boolean>();

    constructor(private amoTableService: AmoTableService,) {
      
    }

    checkEvent($event): void {
        this.checking.emit($event);
    }

    clickToButtonEvent($event: TAmoTableEvents): void {
        this.amoTableService.setNewEvent($event);
    }
}
