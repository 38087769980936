import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'svg[support-cross-image]',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './support-cross.component.svg',
    styleUrls: ['./support-cross.component.scss'],
    host: {
        width: "10",
        height: "10",
        viewBox: "0 0 10 10",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg"
    }
})
export class SupportCrossComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
