import { ActivatedRouteSnapshot, CanActivateFn } from '@angular/router';
import { langData } from '../error-log-page.lang';
import { LanguageService } from 'src/app/translate/language.service';
import { inject } from '@angular/core';
import { PostMessageService } from 'src/app/services/contentServices/PostMessage/post-message.service';
import { combineLatest, filter, first, mergeMap, of, Subscription, tap } from 'rxjs';
import { DataHistoryInstanceService } from '../services/history/data-history-instance.service';
import { GET_PROCESSES_LIST_MESSAGE_KEY, TFiltersHistoryPage, TProcessesListResponseMessage } from '../services/history/data-history-instance.types';
import { DataHistoryPageProcessesService } from '../services/processes/data-history-page-processes.service';
import { AmoTableBodyRow } from '../amo-table/models/amo-table-body-row.model';
import { THistoryPageProcesses } from '../services/processes/data-history-page-processes.types';
import { AmoTableFilterService } from '../amo-table/services/filter/amo-table-filter.service';
import { AmoOverlayService } from 'src/app/htmlElements/amo/amo-overlay/amo-overlay.service';
import { AMO_FILTER_ID_PROCESS } from '../amo-table/services/filter/amo-table-filter.types';

export const errorLogPageGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state) => {
    let languageService: LanguageService = inject(LanguageService);
    let postMessageService: PostMessageService = inject(PostMessageService);
    let dataHistoryInstanceService: DataHistoryInstanceService = inject(DataHistoryInstanceService);
    let dataHistoryPageProcessesService: DataHistoryPageProcessesService = inject(DataHistoryPageProcessesService);
    let amoTableFilterService: AmoTableFilterService<TFiltersHistoryPage> = inject(AmoTableFilterService);
    let amoOverlayService: AmoOverlayService = inject(AmoOverlayService);
    amoOverlayService.show();
    dataHistoryPageProcessesService.getProcessesHistoryInDB();
    postMessageService.enablePostMessageEvent();
    languageService.setLangData(langData[languageService.getLang()]);
    if (!!route.queryParams['use_post_origin']) {
        postMessageService.setUsePostOrigin(route.queryParams['use_post_origin']);
        let subscribe: Subscription = postMessageService.getPostMessageEvent().pipe(
            filter((event: MessageEvent<any>) => event?.data?.key === GET_PROCESSES_LIST_MESSAGE_KEY)
        ).subscribe((event: MessageEvent<TProcessesListResponseMessage>) => {
            if (!event?.data?.processes) {
                throw new Error('Invalid message');
            }
            dataHistoryPageProcessesService.setProcessesHistoryPage(event.data.processes);
            subscribe.unsubscribe();
        });
        postMessageService.sendMessage('ready');
    }
    if (!!route.queryParams['filter_ids']) {
        let filterPageIdProcess: string[] = route.queryParams['filter_ids'].split(',');
        amoTableFilterService.addInFilter(AMO_FILTER_ID_PROCESS, filterPageIdProcess);
    } else {
        amoTableFilterService.resetFilter();
    }

    return combineLatest([
        dataHistoryInstanceService.getHistoryInstanceSubject(),
        dataHistoryPageProcessesService.getProcessesHistoryPage()
    ]).pipe(
        filter(([historyInstance, processes]: [historyInstance: AmoTableBodyRow[], processes: THistoryPageProcesses]) => {
            return (!!historyInstance && !!processes);
        }),
        first(),
        mergeMap(() => {
            return of(true);
        })
    );
};
