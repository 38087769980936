import {Inject, Injectable, Renderer2, RendererFactory2} from '@angular/core';

import { StorageService } from './storage.service';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute, Router, RoutesRecognized } from '@angular/router';
import { EnvService } from '../models/env';

@Injectable({
    providedIn: 'root'
})
export class ColorSchemeService {

    private renderer: Renderer2;
    private static colorScheme: string = 'light';
    // Define prefix for clearer and more readable class names in scss files
    private colorSchemePrefix = 'color-scheme-';

    constructor(
        rendererFactory: RendererFactory2,
        private storageService: StorageService,
        @Inject(DOCUMENT) private document: Document,
        private route: Router,
        private envService: EnvService,
    ) {
        // Create new renderer from renderFactory, to make it possible to use renderer2 in a service
        this.renderer = rendererFactory.createRenderer(null, null);
    }

    _detectPrefersColorScheme() {
        // Detect if prefers-color-scheme is supported
        let colorScheme: string = 'light';
        if (window.matchMedia('(prefers-color-scheme)').media !== 'not all') {
            // Set colorScheme to Dark if prefers-color-scheme is dark. Otherwise, set it to Light.
            colorScheme = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
        } else {
            // If the browser does not support prefers-color-scheme, set the default to dark.
            colorScheme = 'dark';
        }
        this._setColorScheme(colorScheme);
    }

    private getNextColorTheme(): string {
        return (this.isDarkTheme() ? 'light' : 'dark');
    }

    isDarkTheme(): boolean {
        return ColorSchemeService.colorScheme === 'dark';
    }

    static isDarkTheme(): boolean {
        return ColorSchemeService.colorScheme === 'dark';
    }

    changeColorScheme(): void {
        this.update(this.getNextColorTheme());
    }

    private _setColorScheme(scheme: string, saveToLocalStorage: boolean = true) {
        ColorSchemeService.colorScheme = scheme;
        // Save prefers-color-scheme to localStorage
        if(saveToLocalStorage){
            this.storageService.setItem('prefers-color', scheme);
        }
    }

    _getColorScheme() {
        const localStorageColorScheme = this.storageService.getItem('prefers-color');
        // Check if any prefers-color-scheme is stored in localStorage
        if (localStorageColorScheme) {
            // Save prefers-color-scheme from localStorage
            ColorSchemeService.colorScheme = localStorageColorScheme;
        } else {
            // If no prefers-color-scheme is stored in localStorage, try to detect OS default prefers-color-scheme
            this._detectPrefersColorScheme();
        }
    }

    load() {
        this.route.events.subscribe((data) => {
            if (data instanceof RoutesRecognized) {
                if (!!data.state.root.firstChild.data.page_state) {
                    //TODO убрать костыль когда добавим темную тему для календаря
                    if (data.state.root.firstChild.data.page_state === 'work_calendar' && this.isDarkTheme()) {
                        this.renderer.removeClass(document.body, this.colorSchemePrefix + 'dark');
                        ColorSchemeService.colorScheme = 'light';
                    }
                    //TODO убрать костыль когда добавим темную тему для чата
                    if (data.state.root.firstChild.data.page_state === 'support_chat' && this.isDarkTheme()) {
                        this.renderer.removeClass(document.body, this.colorSchemePrefix + 'dark');
                        ColorSchemeService.colorScheme = 'light';
                    }
                    this.loadStyle(`${data.state.root.firstChild.data.page_state}-theme-${ColorSchemeService.colorScheme}`);
                } else {
                    this.loadStyle(`constructor-theme`);
                }
            }
        });
        this._getColorScheme();
        this.renderer.addClass(document.body, this.colorSchemePrefix + ColorSchemeService.colorScheme);
    }

    update(scheme: string, saveToLocalStorage: boolean = true) {
        this._setColorScheme(scheme, saveToLocalStorage);
        // Remove the old color-scheme class
        this.renderer.removeClass(document.body, this.colorSchemePrefix + (this.isDarkTheme() ? 'light' : 'dark'));
        // Add the new / current color-scheme class
        this.renderer.addClass(document.body, this.colorSchemePrefix + scheme);
    }

    currentActive() {
        return ColorSchemeService.colorScheme;
    }

    static currentActive() {
        return ColorSchemeService.colorScheme;
    }

    public static dosomething() {
        return ColorSchemeService.colorScheme;
    }

    loadStyle(styleName: string) {
        const head = this.document.getElementsByTagName('head')[0];

        let themeLink = this.document.getElementById('client-theme') as HTMLLinkElement;

        if (themeLink && !themeLink.href.includes(styleName)) {
          themeLink.href = `${this.envService.getConstructorHostWithEnv()}${styleName}.css`;
        } else {
          const style = this.document.createElement('link');
          style.id = 'client-theme';
          style.rel = 'stylesheet';
          style.href = `${this.envService.getConstructorHostWithEnv()}${styleName}.css`;

          head.appendChild(style);
        }
        this.renderer.addClass(document.body, this.colorSchemePrefix + ColorSchemeService.colorScheme);
      }
}
