import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TrashCanComponent } from './trash-can.component';

@NgModule({
    declarations: [TrashCanComponent],
    imports: [
        CommonModule
    ],
    exports: [TrashCanComponent]
})
export class AmoTrashCanModule { }
