import { ActivatedRoute, ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { inject, Injectable, OnDestroy } from "@angular/core";
import { EnvService } from "src/app/models/env";
import { IndexedBDServiceService } from "src/app/services/contentServices/IndexedBd/indexed-bdservice.service";
import {
    TCatchData
} from "src/app/content/amo/support-chat/pages/errors/blocking-time/blocking-time-html/blocking-time-html.types";
import { firstValueFrom, Subscription, take, takeLast, timeout } from "rxjs";
import {
    ApplicationsDataService
} from "src/app/content/amo/support-chat/services/data-services/applications/applications-data.service";
import { DataAccountService } from "src/app/services/data-account.service";
import { TApplicationData } from "src/app/content/amo/support-chat/models/ApplicationData";

@Injectable({
    providedIn: 'root'
})
class BlockingTimeService implements OnDestroy {
    private subscriptions: Subscription[] = [];

    constructor(
        private envService: EnvService,
        private router: Router,
        private indexedBDServiceService: IndexedBDServiceService,
        private applicationsDataService: ApplicationsDataService,
        private route: ActivatedRoute,
        private dataAccountService: DataAccountService,
    ) {
    }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        if (state.url.includes('blocking_time') || state.url.includes('request_limit')) {
            return true;
        }
        const catchData: TCatchData = await this.indexedBDServiceService.getItemAsync('support_chat_blocking-time', {});
        let currentTime: number = Math.floor(Date.now() / 1000);
        if (catchData?.end > currentTime && !state.url.includes('blocking_time')) {
            await this.router.navigate([`/amo/support_chat/${ this.envService.amoId }/blocking_time`]);
            return false;
        }
        let appData: TApplicationData[] = await firstValueFrom(this.applicationsDataService.getApplications().pipe(
            take(1),
            timeout(500),
        ), {defaultValue: null});

        if (!appData) {
            let applicationsSubs: Subscription = this.applicationsDataService.updateApplicationsHttpData().getApplications().subscribe(async (applications: TApplicationData[]): Promise<void> => {
                const isInaccessibility: boolean = await firstValueFrom(this.applicationsDataService.getIsInaccessibility());
                if (isInaccessibility) {
                    await this.router.navigate([`/amo/support_chat/${ this.envService.amoId }/request_limit`]);
                    return;
                }
                if (applications) {
                    await this.router.navigate([`/amo/support_chat/${ this.envService.amoId }/applications`]);
                }
            });
            this.subscriptions.push(applicationsSubs);
            return false;
        }

        return true;
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => {
            subscription.unsubscribe();
        })
    }
}

export const supportChatCheckBlockingTimeGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    return inject(BlockingTimeService).canActivate(route, state);
};
