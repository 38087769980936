import { Injectable, OnDestroy } from '@angular/core';
import { de } from 'date-fns/locale';
import { Observable, BehaviorSubject, Subscription } from 'rxjs';

import { AmoUsers } from 'src/app/models/amoUsers';

import { AccountService } from './account.service';
import {EnvService} from '../models/env';
import { IndexedBDServiceService } from '../services/contentServices/IndexedBd/indexed-bdservice.service';
import { IStandartResponse } from './handle-error.service';
import { SelectOption } from '../htmlElements/select/select.types';
import { LanguageService } from '../translate/language.service';
import { ElementsGrouped } from '../htmlElements/listGroup/listGroup.types';
import { DataSuperFunctionService } from './dataServices/data-superfunctions.service';

export type TAccountUsers = {
    active: boolean,
    amo_profile_id: any,
    catalog_rights: any,
    catalogs_access: string,
    custom_fields_rights: any[],
    files_access: string,
    free_user: boolean,
    group_id: number,
    id: string,
    is_admin: string,
    language: string,
    last_name: string | null,
    login: string,
    mail_admin: string,
    name: string,
    oper_day_reports_view_access: string,
    oper_day_user_tracking: string,
    phone_number: any,
    photo_url: string,
    rights_by_status: any,
    rights_company_add: string,
    rights_company_delete: string,
    rights_company_edit: string,
    rights_company_export: string,
    rights_company_view: string,
    rights_contact_add: string,
    rights_contact_delete: string,
    rights_contact_edit: string,
    rights_contact_export: string,
    rights_contact_view: string,
    rights_lead_add: string,
    rights_lead_delete: string,
    rights_lead_edit: string,
    rights_lead_export: string,
    rights_lead_view: string,
    rights_task_delete: string,
    rights_task_edit: string,
    unsorted_access: string,
    user_rank: string,
};

export type TaccountUrlObject = {
    url: string
};

export type TAccountCustomField = {
    group_name?: string | number,
    fields?: number[],
    account_id: number,
    chained_lists: null | any,
    code: string,
    currency: string,
    disabled: string,
    filter_type: null | any,
    id: string,
    is_deletable: boolean,
    is_required: boolean,
    is_visible: boolean,
    multiple: string,
    name: string,
    search_in: string,
    sort: number,
    tracking_callback: string,
    triggers: null | any,
    type_id: string,
    enums?: {[id: number]: string}
}

export type TAccountUserGroups = {
    id: any;
    name: string;
}

export type TAccount = {
    amo_chats_state: string,
    amocrm_drive: TaccountUrlObject,
    amojo_base_url: string,
    amojo_rights: {
        can_direct: boolean,
        can_group_create: boolean
    },
    amopay: TaccountUrlObject,
    contact_name_display_order: number,
    country: string,
    currency: string,
    current_user: number,
    custom_fields: TAccountCustomFields,
    custom_fields_groups: any,
    customers_enabled: string,
    customers_mode: string,
    date_format: string,
    date_pattern: string,
    groups: TAccountUserGroups[],
    helpbot_enabled: boolean,
    id: string,
    invoices: {
        catalog_id: number
    },
    language: string,
    leads_statuses: any,
    limits: {
        users_count: number,
        contacts_count: number,
        active_deals_count: number
    },
    loss_reasons_enabled: boolean,
    mobile_feature_version: number,
    name: string,
    note_types: any,
    notifications_base_url: string,
    notifications_ws_url: string,
    notifications_ws_url_v2: string,
    periodicity_enabled: string,
    pipelines: TAmoAccountPipelines,
    short_date_pattern: {
        date: string,
        time: string,
        date_time: string,
    },
    subdomain: string,
    task_types: any,
    time_format: string,
    timezone: string,
    timezoneoffset: string,
    unsorted_on: string,
    users: TAccountUsers[],
    uuid: string,
    version: number
}

export type TAccountCustomFields = {
    [id: string]: TAccountCustomField[]
}

export type AccountDataType = {
    account: TAccount,
    amo_user_id: number,
    constructor: any,
    mask_list: any,
    telegram_users: any,
    superfunction_version: number,
}

export type TAmoAccountPipelines = {
    [id: number]: TAmoAccountPipeline
}

export type TAmoAccountPipeline = {
    id: number,
    is_archive: boolean,
    is_main: boolean,
    is_unsorted_on: boolean,
    label: string,
    leads: number,
    name: string,
    sort: number,
    statuses: TAmoAccountPipelineStatuses,
    value: number,
}

export type TAmoAccountPipelineStatuses = {
    [id: number]: TAmoAccountPipelineStatus
}

export type TAmoAccountPipelineStatus = {
    color: string,
    editable: string,
    id: number,
    name: string,
    pipeline_id: number,
    sort: number,
    type: number,
}

@Injectable({
  providedIn: 'root'
})
export class DataAccountService implements OnDestroy {

    private accountSubject = new BehaviorSubject<AccountDataType>(null);
    private amoUsersSubject = new BehaviorSubject<AmoUsers>(null);
    private subdomain: string;

    private subscriptions: Subscription[] = [];

    constructor(
        private accountService: AccountService,
        private envService: EnvService,
        private users: AmoUsers,
        private indexedDBService: IndexedBDServiceService,
        private dataSuperFunctionService: DataSuperFunctionService
    ) {}

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
    }

    setUsersData(): DataAccountService {
        let setUsersSubscription: Subscription = this.getAccount().subscribe(items => {
            if (!!items && !!items.account) {
                !!items.account.amojo_base_url && !!items.account.subdomain && this.users.setAmoAccountUrl(items.account.amojo_base_url, items.account.subdomain);
                this.users.setUsersData(items.account.users);
                this.amoUsersSubject.next(this.users);
            }
        });
        this.subscriptions.push(setUsersSubscription);
        return this;
    }

    getAmoUsersMessages(): Observable<any> {
        return this.amoUsersSubject.asObservable();
    }

    getAmoUserId(): number {
        return this.envService.getCurrentUserAmoId();
    }

    /**
     * Получить данные аккаунта amoCRM
     * @returns
     */
    getAccount(): Observable<AccountDataType> {
        return this.accountSubject.asObservable();
    }

    getAccountTimezone(): string {
        return this.accountSubject?.value?.account?.timezone || 'Europe/Moscow';
    }

    getAccountTimezoneOffset(): string {
        return this.accountSubject?.value?.account?.timezoneoffset || '+03:00';
    }

    getAccountLanguage(): string {
        return this.accountSubject?.value?.account?.language || 'ru';
    }

    get domain(): string {
        return `${this.subdomain}.amocrm.ru`;
    }

    setSubdomain(str: string): DataAccountService {
        this.subdomain = str;
        return this;
    }

    getAccountAddress(): string {
        return this.users.getAmoAccountUrl() || '';
    }

    getAccountsData(): DataAccountService {
        this.indexedDBService.getItemAsync('account_data').then((accountData: AccountDataType) => {
            if (!!accountData) {
                this.setSubdomain(accountData.account.subdomain).accountSubject.next(accountData);
                this.dataSuperFunctionService.setSuperfunctionVersion(accountData.superfunction_version);
            }

            this.getMaskListFromIndexedDb();
            this.accountService.getAccount().subscribe((res: IStandartResponse<AccountDataType>) => {
                if (!res.data) {
                    return;
                }
                this.dataSuperFunctionService.setSuperfunctionVersion(res.data.superfunction_version);
                if (res.data.account) {
                    // Метод амо, из которого берутся эти данные кодирует все html символы в &lt;
                    res.data.account = this.decodeHtmlEntities(res.data.account);
                    this.setSubdomain(res.data.account.subdomain);
                }

                this.accountSubject.next(res.data);
                this.indexedDBService.setItem('mask_list', res.data.mask_list);
                this.indexedDBService.setItem('account_data', res.data);
            });
        });

        return this;
    }

    private decodeHtmlEntities(data: any): any {
        let decoderElement = document.createElement('div');

        function decode(value: string): string {
            decoderElement.innerHTML = value;
            return decoderElement.textContent;
        }

        function parseValue(value: any) {
            if(!value) {
                return value;
            }
            if(typeof value === 'string') {
                return decode(value);
            } else if(Array.isArray(value) || typeof value === 'object') {
                return parse(value);
            }

            return value;
        }

        function parse(data: any) {
            if(!!data && typeof data == 'object') {
                if(Array.isArray(data)) {
                    for(let i = 0; i < data.length; i++){
                        data[i] = parseValue(data[i]);
                    }
                } else {
                    Object.keys(data).forEach((key) => {
                        data[key] = parseValue(data[key]);
                    });
                }
            }

            return data;
        }

        parse(data);

        return data;
    }

    private getMaskListFromIndexedDb () {
        this.indexedDBService.getItemAsync('mask_list').then((masks) => {
            this.accountSubject.next({
                ...this.accountSubject.value, mask_list: masks
            });
        });
    }
}
