import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { EnvService } from 'src/app/models/env';
import { TEnableFeatures } from 'src/app/models/EnableFeatures';
import { ActivatedRoute, Router } from '@angular/router';
import { ErrorModalService } from "../content/amo/support-chat/html-elements/error-modal/error-modal.service";

export type UserInBody = {
    id: number,
    is_admin: boolean
};

@Injectable()
export class ApiInterceptor implements HttpInterceptor {

    constructor(private envService: EnvService, private router: Router, private route: ActivatedRoute, private errorModalService: ErrorModalService) {}

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            tap(
                (event) => {
                    if (event instanceof HttpResponse) {
                        if (!!event?.body?.features) {
                            this.envService.setEnableFeatures(event.body.features as TEnableFeatures);
                        }
                        if (!!event?.body?.user) {
                            this.envService.setIsAdmin((event?.body?.user as UserInBody).is_admin);
                        }
                        if (!!event?.body?.user) {
                            this.envService.setCurrentUserAmoId((event?.body?.user as UserInBody).id);
                        }
                        if (!!event?.body?.subscription) {
                            this.envService.setIsDemo(!!event?.body?.subscription?.is_demo);
                        }
                    }

                }, (err) => {
                    if (err instanceof HttpErrorResponse) {
                        if(err.status === 429) {
                            this.router.navigate(['/amo/support_chat/' + this.route.snapshot.firstChild.paramMap.get('amo_account_id') + '/blocking_time']);
                        } else if(err.status === 413) {
                            this.errorModalService.show(new URL(err.url).pathname);
                        }
                    }
                }
            )
        )
    }
}
