import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'svg[amo-trash-can]',
    templateUrl: './trash-can.component.html',
    styleUrls: ['./trash-can.component.css'],
    host: {
        width: "13",
        height: "14", 
        viewBox: "0 0 20 24", 
        fill: "none", 
        xmlns: "http://www.w3.org/2000/svg",
        'xmlns:xlink': "http://www.w3.org/1999/xlink"
    }
})
export class TrashCanComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

}
