import { FormControl } from "@angular/forms";
import { AMO_FILTER_ID_PROCESS, AMO_FILTER_LAST_ELEMENT } from "../services/filter/amo-table-filter.types";

export type TAmoTableEvents = 'open_stop_process_modal' | 'open_start_process_modal' | 'delete';

export const AMO_TABLE_EVENT_STOP_PROCESS: TAmoTableEvents = 'open_stop_process_modal';
export const AMO_TABLE_EVENT_RESTART_PROCESS: TAmoTableEvents = 'open_start_process_modal';
export const AMO_TABLE_EVENT_DELETE: TAmoTableEvents = 'delete';

export type TAmoTableFilterTypes = 'input' | 'select' | 'date' | 'range-with-time' | 'multi-select';

export const AMO_TABLE_EVENT_FILTER_TYPE_INPUT: TAmoTableFilterTypes = 'input';
export const AMO_TABLE_EVENT_FILTER_TYPE_SELECT: TAmoTableFilterTypes = 'select';
export const AMO_TABLE_EVENT_FILTER_TYPE_MULTI_SELECT: TAmoTableFilterTypes = 'multi-select';
export const AMO_TABLE_EVENT_FILTER_TYPE_DATE: TAmoTableFilterTypes = 'date';
export const AMO_TABLE_EVENT_FILTER_TYPE_RANGE_WITH_TIME: TAmoTableFilterTypes = 'range-with-time';

export type TAmoFilterSelectOption = { label: string, value: any };

export type TAmoTableEventStopRestartProcess = {id: string, process_item_id: string};

export type TAmoTableFilterValue = string | string[];

export type TAmoTableFilter = {
    id: TAmoTableFilterId;
    type: TAmoTableFilterTypes;
    order: number;
    value: TAmoTableFilterValue;
    preText?: string;
    placeholder?: string;
    options?: TAmoFilterSelectOption[];
}

export interface TAmoTableForm {
    description: FormControl<string>,
    enabled: FormControl<boolean>,
    name: FormControl<string>
    scenario_group_id: FormControl<string>,
    event_id: FormControl<string>,
    expresion: FormControl<string>
}

export type TDateTimeRange = { from: string, to: string };

export const AMO_TABLE_FILTER_PROCESS_STATUS = 'status';
export const AMO_TABLE_FILTER_ID_LEAD_NAME = 'lead_name';
export type TAmoTableFilterId = typeof AMO_TABLE_FILTER_ID_LEAD_NAME | typeof AMO_TABLE_FILTER_PROCESS_STATUS | typeof AMO_FILTER_ID_PROCESS | typeof AMO_FILTER_LAST_ELEMENT;

export const AMO_TABLE_DEFAULT_VALUE = 'default';

export const AMO_TABLE_START_PROCESS_RESULT = 'start_processes_modal';
export const AMO_TABLE_STOP_PROCESS_RESULT = 'stop_processes_modal';
export const AMO_TABLE_PROCESS_ACTION_RESULT = 'process_action_result';
