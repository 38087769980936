import { Injectable, Injector, Renderer2 } from '@angular/core';
import { NgElement, WithProperties } from '@angular/elements';
import { BaseAEService } from 'src/app/helpers/BaseAEService';
import { EventsRowComponent } from '../events-row/events-row.component';
import { AmoTableModel } from '../models/amo-table.model';
import { AmoTableBodyRow } from '../models/amo-table-body-row.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { TAmoTableEvents } from '../models/amo-table.types';

@Injectable({
    providedIn: 'root'
})
export class AmoTableService extends BaseAEService {
    private eventSubject: BehaviorSubject<TAmoTableEvents> = new BehaviorSubject<TAmoTableEvents>(undefined);
    private popupEl: NgElement & WithProperties<EventsRowComponent> | undefined;
    private headerEl: Element | undefined;
    private amoTableModel: AmoTableModel | undefined;
    private renderer: Renderer2;

    constructor(private injector: Injector) {
        super('amo-table-events-row-ae', injector, EventsRowComponent);
    }

    public setRenderer(renderer: Renderer2): this {
        this.renderer = renderer;
        return this;
    }

    public setAmoTableModel(amoTableModel: AmoTableModel): this {
        this.amoTableModel = amoTableModel;
        return this;
    };

    public getAmoTableModel(): AmoTableModel | undefined {
        return this.amoTableModel;
    }

    private getHeaderEl(): Element {
        return this.headerEl;
    }

    public setHeaderEl(headerEl: Element): this {
        this.headerEl = headerEl;
        return this;
    }

    public show(state: string, parentNode?: Element): void {
        if (!!this.popupEl) {
            this.hide();
        }
        this.popupEl = document.createElement(this.componentName) as any;
        this.popupEl.addEventListener('checking', () => {
            this.onCheckingAll(false);
            this.hide();
        });

        this.popupEl.style.width = this.getParent().getBoundingClientRect().width + 'px';
        this.popupEl.events = this.amoTableModel?.getEvents() || [];
        this.popupEl.totalChecked = 0;

        if (state === 'all') {
            this.onCheckingAll(true);
            this.popupEl.state = false;
            this.getHeaderEl().appendChild(this.popupEl);
        } else if (parentNode instanceof Element) {
            parentNode.appendChild(this.popupEl);
        }
        
    }

    public hide(): this {
        if (!this.popupEl) {
            return this;
        }
        this.onCheckingAll(false);
        this.popupEl.parentNode.removeChild(this.popupEl);
        this.popupEl = undefined;
        
        return this;
    }

    private onCheckingAll(flag: boolean): void {
        this.amoTableModel.getRows().forEach((model: AmoTableBodyRow) => {
            model.setChecked(flag);
            if (flag) {
                this.renderer.addClass(model.getEl(), 'checked');
            } else {
                this.renderer.removeClass(model.getEl(), 'checked');
            }
        });
        if (!!this.popupEl) {
            this.popupEl.totalChecked = flag ? this.amoTableModel.getRows().length : 0;
        }
    }

    public onCheckindById(): this {
        let checkedModels: number = 0;
        this.amoTableModel.getRows().forEach((model: AmoTableBodyRow) => {
            if (model.getChecked()()) {
                this.renderer.addClass(model.getEl(), 'checked');
                checkedModels++;
            } else {
                this.renderer.removeClass(model.getEl(), 'checked');
            }
        });

        if (checkedModels === 0) {
            this.hide();
        } else if (checkedModels === 1) {
            let checkedModel: AmoTableBodyRow = this.amoTableModel.getRows().find(el => el.getChecked()());
            if (!checkedModel) {
                this.hide();
            }
            this.renderer.removeClass(checkedModel.getEl(), 'checked');
            if (!this.popupEl) {
                this.show('one', checkedModel.getEl());
            } else {
                checkedModel.getEl().appendChild(this.popupEl);
            }
            this.popupEl.totalChecked = 0;
            this.popupEl.state = false;
            this.popupEl.style.width = 'auto';
        } else {
            let checkedModels: AmoTableBodyRow[] = this.amoTableModel.getRows().filter(el => el.getChecked()());
            if (!checkedModels) {
                this.hide();
            }

            if (!this.popupEl) {
                this.show('more', this.getHeaderEl());
            } else {
                this.getHeaderEl().appendChild(this.popupEl);
            }
            this.popupEl.totalChecked = checkedModels.length;
            this.popupEl.state = checkedModels.length < this.amoTableModel.getRows().length;
            this.popupEl.style.width = this.getParent().getBoundingClientRect().width + 'px';
        }
        return this;
    }

    public setNewEvent(event: TAmoTableEvents): this {
        this.eventSubject.next(event);
        return this;
    }

    public getEventSubject(): Observable<TAmoTableEvents> {
        return this.eventSubject.asObservable();
    }

    public clearTable(): void {
        this.onCheckingAll(false);
        this.getAmoTableModel().getFilters().forEach(filter => filter.resetValue());
        Object.values(this.getHeaderEl().getElementsByClassName('filter-on') || []).forEach(element => {
            if (!(element instanceof Element)) {
                return;
            }
            this.renderer.removeClass(element, 'filter-on');
        });
    }
}
