import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { IStandartResponse } from 'src/app/services/handle-error.service';

import { EnvService } from 'src/app/models/env';
import { Observable } from 'rxjs';
import { SelectOption } from 'src/app/htmlElements/select/select.types';
import { ElementsGrouped, ElementsGroupedGroup } from "../../htmlElements/listGroup/listGroup.types";

export type TSuperfunctionDataFunctionParameter = {
    code_name: string,
    default_value: string,
    is_required: boolean,
    element_grouped?: ElementsGrouped[],
    select_option?: SelectOption[],
    select_option_for_name?: SelectOption[], //
    name?: string, //название то что пишем выше инпута
    placeholder?: string, //плейсхолдер внутри инпута
    value_units?: string, //Ед. Ч М,
    prefix?: string, //для two_input and two_input_mask (/, от-до...)
};
//number_mask two_input two_input_mask
export type TSuperfunctionDataParameterRows = {
    parameters: TSuperfunctionDataFunctionParameter[],
    description?: string,
    row_type:  'string' | 'number' | 'string_mask' | 'number_mask' | 'two_input' | 'textarea' | 'textarea_mask' | 'is_error' | 'select' | 'filter' | 'two_input_mask' | 'element_grouped',
};

export type TAccountSuperfunctionDataFunction = {
    code_name: string,
    description: string,
    name: string,
    parameter_rows: TSuperfunctionDataParameterRows[],
    return_type: string, //Возможно тоже не нужен
    is_modifier: boolean,
};

export type TAccountSuperfunctionData = {
    code_name: string,
    // description: string,
    functions: TAccountSuperfunctionDataFunction[],
    name: string
}

@Injectable({
    providedIn: 'root',
})
export class SuperFunctionsHttpService {

    constructor(
            private httpClient: HttpClient,
            private envService: EnvService
    ) { }

    /**
     * Метод отправляет тестовое письмо
     * @returns
     */
    getSuperFunctionsData(): Observable<IStandartResponse<TAccountSuperfunctionData[]>> {
        return this.httpClient.get<IStandartResponse<TAccountSuperfunctionData[]>>
            (`${this.envService.serverUrl}/v1/element/superfield/list_superfunctions`, this.envService.httpOptions);
    }
}
