import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'spinner',
    templateUrl: './spinner.component.html',
    styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit {

    @Input() fixPosition: boolean = false;
    @Input() fillColor: string = 'var(--spinner-color)';

    constructor() { }

    ngOnInit(): void {
    }

}
