import { Injectable } from '@angular/core';
import { BehaviorSubject, filter, Observable, take } from 'rxjs';
import {
    AMO_FILTER_ID_LEAD,
    AMO_FILTER_LAST_ELEMENT,
    AMO_FILTER_LIMIT,
    AMO_FILTER_PAGE,
    TAmoFilterBase
} from './amo-table-filter.types';
import { PostMessageService } from 'src/app/services/contentServices/PostMessage/post-message.service';
import { GET_ID_LEADS_FOR_NAME_MESSAGE_KEY, GET_ID_LEADS_FOR_NAME_RESPONSE_MESSAGE_KEY, THistoryInstanceIdsLeadsForNameResponseMessage } from '../../../services/history/data-history-instance.types';
import { AMO_TABLE_FILTER_ID_LEAD_NAME } from '../../models/amo-table.types';

@Injectable({
    providedIn: 'root'
})
export class AmoTableFilterService<T extends TAmoFilterBase> {
    private filter: BehaviorSubject<T> = new BehaviorSubject<T>(undefined);
    private activePage: number = 1;
    private pagesQuantity: number = 10;
    private visiblePagesQuantity: number = 5;
    private pointsOffset: number = 2;
    private pageLimit: number = 25;
    private _filter: T;

    constructor(private postMessageService: PostMessageService,) {
        this._filter = this.getDefaultFilter();
    }

    private getDefaultFilter(): T {
        return {
            [AMO_FILTER_LIMIT]: this.pageLimit,
            [AMO_FILTER_PAGE]: this.activePage
        } as T;
    }

    public setPageLimit(pageLimit: number): this {
        this.pageLimit = pageLimit;
        this.addInFilter(AMO_FILTER_LIMIT, pageLimit);
        return this;
    }
    public getActivePage(): number {
        return this.activePage;
    }
    public getPagesQuantity(): number {
        return this.pagesQuantity;
    }
    public getVisiblePagesQuantity(): number {
        return this.visiblePagesQuantity;
    }
    public getPointsOffset(): number {
        return this.pointsOffset;
    }
    public setActivePage(page: number): this {
        this.activePage = page;
        this.addInFilter(AMO_FILTER_PAGE, page);
        return this;
    }
    public setPagesQuantity(pagesQuantity: number): this {
        this.pagesQuantity = pagesQuantity;
        return this;
    }
    public setVisiblePagesQuantity(visiblePagesQuantity: number): this {
        this.visiblePagesQuantity = visiblePagesQuantity;
        return this;
    }
    public setPointsOffset(pointsOffset: number): this {
        this.pointsOffset = pointsOffset;
        return this;
    }
    public addInFilter(key: string, value: any): this {
        if (!value) {
            return this.deletedInFilter(key);
        }
        if (key === AMO_TABLE_FILTER_ID_LEAD_NAME) {
            this.getFilterForLeadName(value);
            return this;
        }

        if (key !== AMO_FILTER_PAGE) {
            this._filter[AMO_FILTER_PAGE] = 1;
            this.activePage = 1;
        }

        this._filter[key] = value;
        this.filter.next(this._filter);
        return this;
    }
    public deletedInFilter(key: string): this {
        if (key === AMO_TABLE_FILTER_ID_LEAD_NAME) {
            key = AMO_FILTER_ID_LEAD;
        }

        if (key !== AMO_FILTER_PAGE) {
            this._filter[AMO_FILTER_PAGE] = 1;
            this.activePage = 1;
        }

        delete this._filter[key];
        this.filter.next(this._filter);
        return this;
    }
    public getChangeFilter(): Observable<T> {
        return this.filter.asObservable();
    }
    public getFilter(): T {
        return this._filter;
    }

    public resetFilter(): void {
        this._filter = this.getDefaultFilter();
        this.filter.next(this._filter);
    }

    getFilterForLeadName(str: string): void {
        this.postMessageService.sendMessage([{
            key: GET_ID_LEADS_FOR_NAME_MESSAGE_KEY,
            name: str
        }]);
        this.postMessageService.getPostMessageEvent().pipe(
            filter((event: MessageEvent<any>) => event?.data?.key === GET_ID_LEADS_FOR_NAME_RESPONSE_MESSAGE_KEY),
            take(1)
        ).subscribe((event: MessageEvent<THistoryInstanceIdsLeadsForNameResponseMessage>) => {
            this.addInFilter(AMO_FILTER_ID_LEAD, event.data.leads || []);
        });
    }

}
