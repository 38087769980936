import { Injectable } from '@angular/core';
import { fromEvent, Observable, BehaviorSubject } from 'rxjs';
import { filter, map } from 'rxjs/operators';

declare var openRequest: IDBOpenDBRequest;
declare var db: IDBDatabase ;

@Injectable({
  providedIn: 'root'
})
export class IndexedBDServiceService {
    private patchEvent = new BehaviorSubject<{key: string, newValue: string}>({key: '', newValue: ''});
    private static objectStoreName: string = "constructor";
    constructor() {
        if (!db) {
            this.checkBD();
        }
    }

    checkBD(): void {
        if (openRequest.readyState == 'pending') {
            setTimeout(()=>{
                this.checkBD();
            });
        } else {
            db = openRequest.result;
        }
    }


    async setItem(key: string, object: Object, catchTime: number = null): Promise<void> {
        const json = JSON.stringify(object);
        let transaction = db.transaction(IndexedBDServiceService.objectStoreName, "readwrite").objectStore(IndexedBDServiceService.objectStoreName);

        let data = {
            id: key,
            text: json,
            created: new Date().getTime(),
        };

        if (catchTime) {
            data['catch_time'] = catchTime;
        }

        let request = transaction.put(data);

        request.onsuccess = () => {
            this.patchEvent.next({key: key, newValue: json});
        };

        request.onerror = function() {
            console.log("Ошибка", request.error);
        };
    }

    getItemAsync<T = any>(key: string, defaultValue: any = null): Promise<T> {
        return new Promise((resolve) => {
            if (!db) {
                let openRequestLocal = indexedDB.open("sensei", 1);
                openRequestLocal.onupgradeneeded = function() {
                    let db = openRequestLocal.result;
                    if (!db.objectStoreNames.contains('constructor')) {
                        db.createObjectStore('constructor', {keyPath: 'id'});
                    }
                };
                openRequestLocal.onsuccess = (event) => { 
                    db = openRequestLocal.result;
                    openRequest = openRequestLocal;
                    this.getItemAsyncLogic(resolve, key, defaultValue);
                };
            } else {
                this.getItemAsyncLogic(resolve, key, defaultValue);
            }
        });
    }

    getItemAsyncLogic(resolve: Function, key: string, defaultValue: any = null) {
            let request = db.transaction(IndexedBDServiceService.objectStoreName, "readonly").objectStore(IndexedBDServiceService.objectStoreName).get(key);
            request.onsuccess = function() {
                if (!!request.result?.catch_time && (new Date().getTime() - request.result.created) > request.result.catch_time) {
                    resolve(defaultValue);
                }
                resolve(request.result ? JSON.parse(request.result.text) : defaultValue);
            };
            request.onerror = function() {
                console.log("Ошибка", request.error);
                resolve(defaultValue);
            };
    }

    itemChangesAfterPatch<T = any>(key: string): Observable<T> {
        return this.patchEvent.pipe(
            filter(event => event.key === key),
            map(event => JSON.parse(event.newValue)),
        );
    }
}
