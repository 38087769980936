import { HttpHeaders } from "@angular/common/http";
import { Injectable, OnDestroy, Inject } from '@angular/core';

import { Observable, BehaviorSubject, skipWhile } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { environment } from 'src/environments/environment';

import { TEnableFeatures, TEnableFeaturesSubject, IEnableFeatures, EnableFeatures} from './EnableFeatures';
export type HttpOptions = {
    headers: HttpHeaders,
    withCredentials: boolean
};

@Injectable({
    providedIn: 'root'
})
export class EnvService {
    public amoId: string;
    private amoHex: string;
    public serverUrl: string = (!!localStorage && localStorage.getItem('senseiApiUrl')) ? localStorage.getItem('senseiApiUrl') : environment.senseiApiUrl;
    private socetBaseUrl: string = (!!localStorage && localStorage.getItem('senseiSocetUrl')) ? localStorage.getItem('senseiSocetUrl') : environment.senseiSocetUrl;
    public sharingMode: boolean = false;
    private isAdmin: boolean = true;
    public currentAmoUserId: number;
    private changesEnableFeatures = new BehaviorSubject<TEnableFeaturesSubject>({key: '', newValue: false});
    private isDemo: boolean = false;

    public enableFeatures: IEnableFeatures = new EnableFeatures(this.changesEnableFeatures);


    setEnableFeatures(enableFeatures: TEnableFeatures): EnvService {
        this.enableFeatures.setEnableFeatures(enableFeatures);
        return this;
    }

    itemChangesAfterPatch(key: string): Observable<boolean> {
        return this.changesEnableFeatures.pipe(
            filter(event => event.key === key),
            map(event => event.newValue),
        );
    }

    itemChangesFeatures(): Observable<boolean> {
        return this.changesEnableFeatures.pipe(
            skipWhile(event => event.key === 'all'),
            map(event => event.newValue),
        );
    }

    public httpOptions: HttpOptions = {
        headers: new HttpHeaders(),
        withCredentials: true
    };

    setIsAdmin(flag: boolean) {
        this.isAdmin = flag;
        if (!this.isAdmin) {
            this.setSharingMode(true);
        }
    }

    setCurrentUserAmoId(value: number): EnvService {
        this.currentAmoUserId = value;
        return this;
    }
    getCurrentUserAmoId(): number {
        return this.currentAmoUserId;
    }

    setAmoId(value: string): EnvService {
        this.amoId = value;
        this.httpOptions.headers = new HttpHeaders({'X-Account': this.amoId});
        return this;
    }

    setAmoHex(value: string): EnvService {
        this.amoHex = value;
        this.httpOptions.headers = new HttpHeaders({'X-Client-Code': this.amoHex});
        return this;
    }

    setSharingMode(sharingMode: boolean): EnvService {
        this.sharingMode = sharingMode;
        return this;
    }

    getSharingMode(): boolean {
        return this.sharingMode;
    }

    get socetUrl(): string {
        return this.socetBaseUrl + this.amoId
    }

    getConstructorHostWithEnv(): string {
        return (environment?.locationHost ? `https://${environment.locationHost}/` : this.getConstructorHost());
    }

    getConstructorHost(): string {
        let host: string = (!!localStorage && localStorage.getItem('location_host')) ? localStorage.getItem('location_host') : location.host;
        if (host === 'localhost:4200') {
            host = 'http://localhost:4200/';
        } else {
            host = `https://${host}/`;
        }
        return host;
    }

    getIsDemo(): boolean {
        return this.isDemo;
    }

    setIsDemo(flag: boolean): this {
        this.isDemo = flag;
        return this;
    }
}
