import { Component } from '@angular/core';

@Component({
    selector: 'amo-overlay',
    standalone: true,
    imports: [],
    templateUrl: './amo-overlay.component.html',
    styleUrl: './amo-overlay.component.scss'
})
export class AmoOverlayComponent {

}
