import { signal, WritableSignal } from "@angular/core";

export type TCellData = string | string[];

export interface IAmoBodyRow {
    setCell(index: number, value: string): this,
    getCell(index: number): TCellData,
    setId(str: string): this,
    getId(): string,
    setChecked(flag: boolean): this,
    getChecked(): WritableSignal<boolean>,
    getCells(): TCellData[],
}

export class AmoTableBodyRow implements IAmoBodyRow {
    private id: string;
    private data: TCellData[] = [];
    private checked = signal(false);
    private el: Element;

    constructor(id: string, data: TCellData[]) {
        if (!id || !Array.isArray(data)) {
            console.debug('non data');
            return this;
        }
        this.setId(id);
        this.data = data;
    }

    setChecked(flag: boolean): this {
        this.checked.set(flag);
        return this;
    }

    getChecked(): WritableSignal<boolean> {
        return this.checked;
    }
    
    getId(): string {
        return this.id;
    }

    setId(str: string): this {
        this.id = str;
        return this;
    }

    setCell(index: number, value: string): this {
        this.data[index] = value;
        return this;
    }

    getCell(index: number): TCellData {
        return this.data[index] || '';
    }

    getCells(): TCellData[] {
        return this.data;
    }

    setEl(el: Element): this {
        this.el = el;
        return this;
    }

    getEl(): Element {
        return this.el;
    }
}