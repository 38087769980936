import {
    ChangeDetectionStrategy,
    Component, effect, ElementRef,
    EventEmitter,
    forwardRef,
    Input,
    OnInit,
    Output,
    Renderer2,
    signal,
    Signal,
    ViewChild,
    WritableSignal
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

export type TCheckmark = 'constructor' | 'amo';

@Component({
    selector: 'app-checkbox',
    templateUrl: './checkbox.component.html',
    styleUrls: ['./checkbox.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CheckboxComponent),
            multi: true
        }
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxComponent implements OnInit, ControlValueAccessor {

    @Input() set checked(e: boolean) {
        this._checked = e;
        this.setChecked();
    };
    @Input() label: string;
    @Input() id: string;
    @Input() onlyBox: boolean = false;
    @Input() line: boolean = false;
    @Input() label_text_ml: string;
    @Input() set disabled (disabled: boolean) {
        this.setDisabledState(!!disabled);
    };
    @Input('max-width') maxWidth: string = '100%';
    @Input() checkmark: TCheckmark = 'constructor';
    @Output() flowUpStatus: EventEmitter<boolean> = new EventEmitter();
    @ViewChild('checkbox') checkbox: ElementRef<HTMLInputElement>;

    public _checked: boolean = false;

    private onChange: Function = (value: any) => {};
    private _disabled: WritableSignal<boolean> = signal(false);

    constructor(private _element: ElementRef, private renderer: Renderer2) {
        effect(() => {
            if (this.checkbox) {
                this.checkbox.nativeElement.disabled = this._disabled();
                if (this._disabled()) {
                    this.renderer.addClass(this._element.nativeElement, 'disabled');
                } else {
                    this.renderer.removeClass(this._element.nativeElement, 'disabled');
                }
            }
        });
    }

    ngOnInit(): void {}

    onChangeCheckbox(e: Event): void {
        e.stopPropagation();
        const $el = (e.target as HTMLInputElement);
        this.flowUpStatus.emit($el.checked);
        this.onChange($el.checked);
    }
    setChecked(): void {
        if (this.checkbox) {
            this.checkbox.nativeElement.checked = this._checked;
        }
    }
    changeCheck(): void {
        this.checked = !this.checked;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
    }

    setDisabledState?(isDisabled: boolean): void {
        this._disabled.set(isDisabled);
    }

    writeValue(checked: boolean): void {
        this._checked = checked;
        this.setChecked();
    }

    public isArray(cell: any): boolean {
        return Array.isArray(cell);
    }
}
