import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { objectGet } from './shared.functions';
import { EnvService } from '../models/env';
import { IndexedBDServiceService } from '../services/contentServices/IndexedBd/indexed-bdservice.service'
import { versions } from 'src/environments/versions';
import { LanguageCache, SupportedLanguage, TLangData } from './lang.types';

const LANGUAGE_CACHE_LIFETIME: number = 3600;
@Injectable({
    providedIn: 'root'
})
export class LanguageService {
    private readonly urlPrefix = '/assets/locale-';
    private static lang: SupportedLanguage = null;
    private langData: TLangData = {};
    private static staticLangData = {};
    private langDataLoaded$ = new Subject<TLangData>();

    constructor(private http: HttpClient, private envService: EnvService, private indexedDBService: IndexedBDServiceService) {
        this.setLang(LanguageService.detectLanguage());
    }

    static detectLanguage(): SupportedLanguage {
        //Для тестирования
        if(localStorage && localStorage.getItem('language')){
            return SupportedLanguage[localStorage.getItem('language')];
        }

        //В некоторых браузерах там лежит, то что ожидается (ru, en), а в некоторых (ru-RU, en-US)
        let navigatorLanguage = navigator.language.split('-')[0];
        if (SupportedLanguage[navigatorLanguage]) {
            return SupportedLanguage[navigatorLanguage];
        }

        return SupportedLanguage.en;
    }

    setLang(lang: SupportedLanguage): void {
        LanguageService.lang = lang;
        this.indexedDBService.getItemAsync('language_data').then((cacheRaw) => {
            let cache: LanguageCache = cacheRaw;

            if(
                !!cache
                && !!cache.data
                && lang == cache.lang
                && versions.build_time == cache.version
                && Math.floor(+new Date()/1000) < cache.updated_at + LANGUAGE_CACHE_LIFETIME
            ){
                this.setLangData(cache.data);

                //subscribe и next не работают в одном потоке
                setTimeout(()=>{
                    this.langDataLoaded$.next(this.langData);
                }, 1);
            } else {
                this.http.get(`${this.envService.serverUrl}/v1/locale/${LanguageService.lang}/get`, this.envService.httpOptions)
                    .subscribe((data: TLangData) => {
                        this.setLangData(data);
                        this.langDataLoaded$.next(this.langData);

                        let newCache: LanguageCache = {
                            lang: lang,
                            updated_at: Math.floor(+new Date()/1000),
                            data: data,
                            version: versions.build_time
                        }
                        this.indexedDBService.setItem('language_data', newCache);
                    });
            }
        });
    }

    public setLangData(data: TLangData): this {
        LanguageService.staticLangData = Object.assign(LanguageService.staticLangData, data);
        this.langData = LanguageService.staticLangData;
        return this;
    }

    getLang(): SupportedLanguage {
        return LanguageService.lang;
    }

    public static getLangStatic(): SupportedLanguage {
        return LanguageService.lang;
    }

    langDataLoaded(): Observable<object> {
        return this.langDataLoaded$.asObservable();
    }

    getData(): object {
        return this.langData;
    }

    private static _translate(data: object, path: string, masks: object): string
    {
        let result: string = objectGet(data, path, path);
        Object.keys(masks).forEach((key: string) => {
            result = result.replaceAll('{{'+key+'}}', masks[key]);
        });
        if (result.toString() === '[object Object]') {
            result = path;
        };
        return result;
    }

    translate(path: string, masks: object = {}): string {
        return LanguageService._translate(this.getData(), path, masks);
    }

    static translate(path: string, masks: object = {}): string
    {
        return LanguageService._translate(LanguageService.staticLangData, path, masks);
    }

    private static _splitNumeral(data: object, path: string): string[] {
        let values: string[] = objectGet(data, path, path).split(',');
        if(values.length >= 3){
            return values;
        } else {
            return [path, path, path];
        }
    }

    splitNumeral(path: string): string[] {
        return LanguageService._splitNumeral(this.getData(), path);
    }

    static splitNumeral(path: string): string[] {
        return LanguageService._splitNumeral(LanguageService.staticLangData, path);
    }

    private static _numeral(numerals: string, number: number): string {
        let parts = numerals.split(',');
        if(parts.length < 3){
            return numerals;
        }

        //Приведение к формату [1, *1, *2-*4, *5-*0]
        let parsedParts = [];
        if(parts.length >= 4){
            parsedParts = parts;
        } else if(parts.length == 3){
            parsedParts = [parts[0], parts[0], parts[1], parts[2]];
        }

        if(number == 1){
            return parsedParts[0]; //1
        } else if(Math.floor(number) != number){
            return parsedParts[3]; //дробные
        } else if(number % 100 >= 11 && number % 100 <= 14){
            return parsedParts[3]; //*11-*14
        } else if((number % 10 > 1 && number % 10 <= 4)){
            return parsedParts[2]; //*2-*4
        } else if(number % 10 == 1){
            return parsedParts[1]; //*1
        } else {
            return parsedParts[3];
        }
    }

    numeral(path: string, number: number, masks: object = {}): string {
        return LanguageService._numeral(this.translate(path, masks), number);
    }

    static numeral(path: string, number: number, masks: object = {}): string {
        return LanguageService._numeral(LanguageService.translate(path, masks), number);
    }
}