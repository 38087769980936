import { Injectable } from "@angular/core";
import { IndexedBDServiceService } from "../services/contentServices/IndexedBd/indexed-bdservice.service";

const TSvgElementsBgColor: string[] = ['gray_light', 'blue_light', 'yellow_light', 'violet_light', 'pink_light', 'orange_light', 'green_light'];

export function getRandomInt(max) {
    return Math.floor(Math.random() * max);
};

export type TUser = {
    id: string,
    name: string,
    image: string,
    phone_number?: string
};

export class User implements TUser {
    id: string;
    name: string;
    image: string = '';
    color: string;
    phone_number: string = '';

    constructor(item: TUser) {
        this.id = item.id + '';
        this.name = item.name;
        this.color = TSvgElementsBgColor[getRandomInt(6)];
        this.phone_number = item.phone_number || '';
    }

    setUserImage(amoAccountUrl: string, photoUrl: string, errorLoadImageFunction: Function): User {
        if (this.id == '1320969') {
            this.image = 'https://bpday.ru/img/1320969.png';
        } else if (this.id == '6454708') {
            this.image = 'https://bpday.ru/img/6454708.jpeg';
        } else {
            this.image = amoAccountUrl + photoUrl;
        }

        let linkId: string = `image-${this.id}`;
        let themeLink = document.getElementById(linkId) as HTMLLinkElement;
        if (!!themeLink) {
            return this;
        }
        const link = document.createElement('link');
        link.href = this.image;
        link.id = linkId;
        link.rel = 'prefetch prerender';
        link.onerror = (e) => {
            this.image = '';
            (typeof errorLoadImageFunction === 'function') && errorLoadImageFunction(this.id);
        };       
        document.getElementsByTagName('head')[0].appendChild(link);
        return this;
    }

    getName(): string {
        return this.name;
    }

    getImage(): string {
        return this.image;
    }

    getColor(): string {
        return this.color;
    }

    getId(): string {
        return this.id;
    }

    getPhoneNumber(): string {
        return this.phone_number;
    }

    getAbbreviation(): string {
        let abbreviation = this.name.split(' ');
        return (abbreviation.length > 1) ? abbreviation[0][0] + abbreviation[1][0] : abbreviation[0][0] + abbreviation[0][1];
    }

}

@Injectable({
    providedIn: 'root'
})
export class AmoUsers {
    private data: {
        [id: string]: User
    } = {};
    private amoAccountUrl: string = '';
    
    constructor(private indexedBDServiceService: IndexedBDServiceService,) {}

    setUsersData(items: any): AmoUsers {
        if (!items) {
            return this;
        }
        this.indexedBDServiceService.getItemAsync('users_image', {}).then((response) => {
            let timer: any;
            let errorLoadImageFunction: Function;
            if (!Object.keys(response).length) {
                errorLoadImageFunction = (id) => {
                    response[id] = true;
                    clearTimeout(timer);
                    timer = setTimeout((): void => {
                        this.indexedBDServiceService.setItem('users_image', response, 604800000);
                    }, 300);
                }
            }
            items.forEach(user => {
                this.data[user.id] = (new User(user));
                if (!response[user.id]) {
                    this.data[user.id].setUserImage(this.amoAccountUrl, user.photo_url, errorLoadImageFunction);
                }
            });
        });
        
        return this;
    }
    
    getUserName(id: number): string {
        if (!!this.data[id]) {
            return this.data[id].name;
        }
        return 'Unknown user';
    }
    
    getAbbreviationUserName(id: number): string {
        if (!!this.data[id]) {
            return this.data[id].getAbbreviation();
        } else {
            return 'UN';
        }
    }

    getUserById(id: number): User | null {
        return (!!this.data[id] ? this.data[id] : null);
    }
    
    getUserImage(id: number): string {
        return !!this.data[id] ? this.data[id].image : '';
    }
    
    setAmoAccountUrl(amojo_base_url: string, name: string): AmoUsers {
        if ((!!amojo_base_url) && (!!name)) {
            this.amoAccountUrl = amojo_base_url.replace('amojo', name);
        }
        return this;
    }

    getAmoAccountUrl(): string {
        return this.amoAccountUrl;
    }
}