import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable } from 'rxjs';
import { EnvService } from 'src/app/models/env';
import { IStandartResponse } from 'src/app/services/handle-error.service';
import { HandleErrorService } from '../../../../../services/handle-error.service';
import { TFiltersHistoryPage, THistoryInstanceResponse } from './data-history-instance.types';
import { AMO_FILTER_LAST_ELEMENT } from "../../amo-table/services/filter/amo-table-filter.types";

@Injectable({
    providedIn: 'root'
})
export class HttpHistoryInstanceService {

    constructor(
        private httpClient: HttpClient,
        private envService: EnvService,
        private HandleErrorService: HandleErrorService,
    ) { }

    getHistoryInstanceData(filterPage: TFiltersHistoryPage): Observable<IStandartResponse<THistoryInstanceResponse>> {
        let params: HttpParams = new HttpParams();
        if (!!filterPage) {
            if (!!filterPage.id_process) {
                filterPage.id_process.forEach((val: string) => {
                    params = params.append('filter[id_process][]', val);
                });
            }
            if (!!filterPage.id_lead) {
                filterPage.id_lead.forEach((val: string) => {
                    params = params.append('filter[id_lead][]', val);
                });
                if (!filterPage.id_lead.length) {
                    params = params.append('filter[id_lead][]', undefined);
                }
            }
            if (!!filterPage.limit) {
                params = params.append('limit', filterPage.limit);
            }
            if (!!filterPage.page) {
                params = params.append('page', filterPage.page);
            }
            if (!!filterPage.status) {
                params = params.append('filter[status]', filterPage.status);
            }
            if (!!filterPage[AMO_FILTER_LAST_ELEMENT]) {
                params = params.append('filter[current_item_id]', filterPage[AMO_FILTER_LAST_ELEMENT]);
            }
        //     if (!!filter.dynamic_segment) {
        //         params = params.set('filter[dynamic_segment]', filter.dynamic_segment.join(','));
        //     }
        }
        const headers = {
            headers: this.envService.httpOptions.headers,
            withCredentials: true,
            params: params
        };
        return this.httpClient.get<IStandartResponse<THistoryInstanceResponse>>
            (`${this.envService.serverUrl}/v1/instance-history/list`, headers);
    }
}
