import { Routes } from "@angular/router";
import { SupportChatGuard } from "src/app/content/amo/support-chat/guards/support-chat.guard";
import {
    supportChatCheckBlockingTimeGuard,
} from "src/app/content/amo/support-chat/guards/support-chat-check-blocking-time.guard";

export const supportChatRoutes: Routes = [
    {
        path: 'no_applications',
        loadComponent: () => import('./pages/no-applications/no-applications.component').then(m => { return  m.NoApplicationsComponent}),
        canActivate: [SupportChatGuard, supportChatCheckBlockingTimeGuard]
    },
    {
        path: 'applications',
        loadComponent: () => import('./pages/applications/applications.component').then(m => m.ApplicationsComponent),
        canActivate: [SupportChatGuard, supportChatCheckBlockingTimeGuard]
    },
    {
        path: 'request_limit',
        loadComponent: () => import('./pages/errors/request-limit/request-limit.component').then(m => m.RequestLimitComponent),
    },
    {
        path: 'blocking_time',
        loadComponent: () => import('./pages/errors/blocking-time/blocking-time.component').then(m => m.BlockingTimeComponent),
        canActivate: [SupportChatGuard, supportChatCheckBlockingTimeGuard]
    }
];
