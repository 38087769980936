import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { EnvService } from "../../../../models/env";
import { ApplicationsDataService } from "../services/data-services/applications/applications-data.service";
import { DataAccountService } from "../../../../services/data-account.service";
import {
    TCatchData
} from "src/app/content/amo/support-chat/pages/errors/blocking-time/blocking-time-html/blocking-time-html.types";
import { IndexedBDServiceService } from "src/app/services/contentServices/IndexedBd/indexed-bdservice.service";

@Injectable({
    providedIn: 'root'
})
export class SupportChatGuard implements CanActivate, OnDestroy {
    subscriptions: Subscription[] = [];
    constructor(private envService: EnvService,
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        let amoAccountId: string = route.pathFromRoot[1].paramMap.get('amo_account_id');
        if (amoAccountId) {
            this.envService.setAmoId(amoAccountId);
            return true;
        }
        return false;
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe())
    }
}
