
<app-checkbox
    [id]="'all'"
    [checked]="true"
    [checkmark]="'amo'"
    [line]="state"
    (flowUpStatus)="checkEvent($event)">
</app-checkbox>
<div class="event-button-container">
    @for (event of events; let idx = $index; track idx) {
        @if (event === AMO_TABLE_EVENT_DELETE) {
            <div class="event-button" [style.order]="idx" (click)="clickToButtonEvent(event)">
                <svg amo-trash-can></svg><div>{{'api_key_page.body_row.buttons.delete' | translate}}</div>
            </div>
        } @else if (event === AMO_TABLE_EVENT_STOP_PROCESS) {
            <div class="event-button" [style.order]="idx" (click)="clickToButtonEvent(event)">
                <svg amo-table-stop-event-image></svg><div>{{'constructor.components.prompt.stop' | translate}}</div>
            </div>
        } @else if (event === AMO_TABLE_EVENT_RESTART_PROCESS) {
            <div class="event-button" [style.order]="idx" (click)="clickToButtonEvent(event)">
                <svg amo-table-restart-event-image></svg><div>{{'constructor.components.prompt.restart' | translate}}</div>
            </div>
        }
    }
</div>

<div class="total-text" *ngIf="totalChecked > 1">{{'api_key_page.header_row.total' | translate}} {{totalChecked}}</div>

