import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'svg[amo-table-restart-event-image]',
    standalone: true,
    imports: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './amo-table-restart-event-image.component.svg',
    styleUrl: './amo-table-restart-event-image.component.scss',
    host: {
        width: "18",
        height: "18", 
        viewBox: "0 0 18 18", 
        fill: "none", 
        xmlns: "http://www.w3.org/2000/svg",
        'xmlns:xlink': "http://www.w3.org/1999/xlink"
    }
})
export class AmoTableRestartEventImageComponent {

}
