import { Injectable } from '@angular/core';
import { fromEvent, Observable, BehaviorSubject } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { IndexedBDServiceService } from 'src/app/services/contentServices/IndexedBd/indexed-bdservice.service';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
    private patchEvent = new BehaviorSubject<{key: string, newValue: string}>({key: '', newValue: ''});

    constructor(private indexedBDServiceService: IndexedBDServiceService) {

    }

    setItem(key: string, object: Object, session = false): void {
        const json = JSON.stringify(object);
        this.patchEvent.next({key: key, newValue: json});
        this.indexedBDServiceService.setItem(key, object);
        (session ? sessionStorage : localStorage).setItem(key, json);
    }

    getItem<T = any>(key: string, defaultValue: any = null, session = false): T {
        const json = (session ? sessionStorage : localStorage).getItem(key);

        if (! json) {
            return defaultValue;
        }

        return JSON.parse(json) || defaultValue;
    }

    patchItem(key: string, object: Object, session = false): void {
        const current = this.getItem(key, {}, session);
        this.setItem(key, {...current, ...object}, session);
    }

    remove(key: string, session = false): void {
        (session ? sessionStorage : localStorage).removeItem(key);
    }

    itemChanges<T = any>(key: string): Observable<T> {
        return fromEvent<StorageEvent>(window, 'storage').pipe(
            filter(event => event.key === key),
            map(event => JSON.parse(event.newValue)),
        );
    }

    itemChangesAfterPatch<T = any>(key: string): Observable<T> {
        return this.patchEvent.pipe(
            filter(event => event.key === key),
            map(event => JSON.parse(event.newValue)),
        );
    }
}
