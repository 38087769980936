export const AMO_FILTER_PAGE = 'page';
export const AMO_FILTER_LIMIT = 'limit';
export const AMO_FILTER_ID_PROCESS = 'id_process';
export const AMO_FILTER_ID_LEAD = 'id_lead';
export const AMO_FILTER_LAST_ELEMENT = 'id_last_element';

export type TAmoFilterBase = {
    limit: number,
    page: number,
}

