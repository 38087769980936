import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BILLING_ROUTING } from "src/app/content/amo/billing/billing-routing";
import { supportChatRoutes } from "./content/amo/support-chat/support-chat.routes";
import { SupportChatGuard } from "./content/amo/support-chat/guards/support-chat.guard";
import {
    supportChatCheckBlockingTimeGuard
} from "src/app/content/amo/support-chat/guards/support-chat-check-blocking-time.guard";
import { errorLogPageGuard } from './content/amo/error-log-page/guard/error-log-page.guard';

//защитники
//import {AuthGuard} from './guards/auth/auth.guard';
//import {NonAuthGuard} from './guards/auth/non-auth.guard';
//
////компоненты
//import {ConstructorComponent} from './content/constructor/constructor.component';

const routes: Routes = [
    //роуты только для вошедших пользователей
//    { path: 'statistics', component: StatisticsComponent, canActivate: [AuthGuard] },
//
//    //публичные роуты
//    { path: 'test', component: TestComponent },
    { path: 'amo/error_log_page/:amo_account_id', loadComponent: () => import('./content/amo/error-log-page/error-log-page.component').then(m => { return m.ErrorLogPageComponent }), data: { page_state: "amo"}, canActivate: [SupportChatGuard, errorLogPageGuard]},
    { path: 'amo/support_chat/:amo_account_id', loadComponent: () => import('./content/amo/support-chat/support-chat.component').then(m => { return m.SupportChatComponent }), data: { page_state: "support_chat"}, children: supportChatRoutes, canActivate: [SupportChatGuard, supportChatCheckBlockingTimeGuard]},
    { path: 'feature-limitation/:feature', outlet: 'feature-limitation', loadChildren: () => import('./content/constructor/routes/features-limitation/features-limitation.module').then(m => { return m.FeaturesLimitationModule }) },
    { path: 'amo/work_calendar/:amo_account_id', loadChildren: () => import('./content/amo/work-calendar/work-calendar.module').then(m => { return m.WorkCalendarModule }), data: { page_state: "work_calendar"} },
    { path: 'calendar/:amo_account_hex', loadChildren: () => import('./content/amo/work-calendar/work-calendar.module').then(m => { return m.WorkCalendarModule }), data: { page_state: "work_calendar"} },
    { path: 'amo/api_key_page/:amo_account_id', loadChildren: () => import('./content/amo/api-key-page/api-key-page.module').then(m => { return m.ApiKeyPageModule}), data: { page_state: "amo"} },
    { path: 'amo/billing/session_popup/:amo_account_id', loadChildren: () => import('./content/amo/billing/session-popup/session-popup.module').then(m => { return m.SessionPopupModule}) },
    BILLING_ROUTING,
    { path: 'monitoring/:amo_account_id/:id', loadChildren: () => import('./content/monitoring/monitoring.module').then(m => { return m.MonitoringModule}) },
    { path: 'feature-limitation/monitoring/:amo_account_id/:id', loadChildren: () => import('./content/monitoring/routes/features-limitation/features-limitation.module').then(m => { return m.FeaturesLimitationModule}) },
    { path: 'constructor/:amo_account_id/:id', loadChildren: () => import('./content/constructor/constructor.module').then(m => { return m.ConstructorModule}) },
    { path: 'page404', loadChildren: () => import('./content/page404/page404.module').then(m => { return m.Page404Module}) },
    { path: 'page403', loadChildren: () => import('./content/page403/page403.module').then(m => { return m.Page403Module}) },
    { path: 'page401', loadChildren: () => import('./content/page401/page401.module').then(m => { return m.Page401Module}) },
    { path: 'page402', loadChildren: () => import('./content/page402/page402.module').then(m => { return m.Page402Module}) },
    { path: 's/:sharing_code', loadChildren: () => import('./content/constructor/constructor.module').then(m => { return m.ConstructorModule}) },
    { path: '**', redirectTo: '/page404', pathMatch: 'full' },
    { path: '', redirectTo: '/page404', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { initialNavigation: 'disabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
