import { Injectable, Injector } from '@angular/core';
import { BaseAEService } from 'src/app/helpers/BaseAEService';
import { AmoOverlayComponent } from './amo-overlay.component';
import { NgElement, WithProperties } from '@angular/elements';

@Injectable({
    providedIn: 'root'
})
export class AmoOverlayService extends BaseAEService {
    private popupEl: NgElement & WithProperties<AmoOverlayComponent>;

    constructor(private injector: Injector) {
        super('amo-overlay-ae', injector, AmoOverlayComponent);
    }

    public show(): void {
        if (!!this.popupEl) {
            return;
        }

        this.popupEl = document.createElement(this.componentName) as any;
        this.getParent().appendChild(this.popupEl);
    }

    public hide(): this {
        if (!this.popupEl) {
            return this;
        }
        this.popupEl.parentNode.removeChild(this.popupEl);
        this.popupEl = undefined;
        
        return this;
    }
}
