import { Component, NgZone } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError, ActivatedRoute } from '@angular/router';

import { LanguageService } from './translate/language.service';

import { ColorSchemeService } from 'src/app/services/ColorSchemeService.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
    title = 'Sensei';
    public isSpinnerVisible: boolean = true;

    constructor(
        private router: Router,
        private colorSchemeService: ColorSchemeService,
        private languageService: LanguageService,
        ngZone: NgZone,
        private route: ActivatedRoute,
    ) {
        this.colorSchemeService.load();
        this.languageService.langDataLoaded().subscribe(data => {
            ngZone.run(() => {
                router.initialNavigation();
            });
        });
        this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                let currentPageUrl: string = '/' + this.route?.snapshot?.firstChild?.url?.join('/');
                this.isSpinnerVisible = !this.route.snapshot.firstChild || (event.url !== currentPageUrl && event.url.indexOf(currentPageUrl) === -1);
            } else if ( event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError) {
                setTimeout(() => {
                    this.isSpinnerVisible = false;
                });
            }
        }, () => {
            this.isSpinnerVisible = false;
        });
    }
}
