import { Injectable } from '@angular/core';
import { fromEvent, Observable, Subject, Subscription } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class PostMessageService {
    private subjectPostMessageEvent = new Subject<MessageEvent>();
    private usePostOrigin: string = document.location.ancestorOrigins[0];

    constructor() { }
    
    setUsePostOrigin(origin: string): void {
        this.usePostOrigin = origin;
    }

    getUsePostOrigin(): string {
        return this.usePostOrigin;
    }

    enablePostMessageEvent(): Subscription {
        return fromEvent(window, 'message').subscribe((e: MessageEvent) => {
            if (e.origin !== this.usePostOrigin) {
                return;
            }
            this.subjectPostMessageEvent.next(e);
        });
    }

    sendMessage(message: any): void {
        if (!this.usePostOrigin) {
            throw new Error('Use post origin must be set before sending messages');
        }
        window.parent.postMessage(message, this.usePostOrigin);
    }

    getPostMessageEvent(): Observable<MessageEvent> {
        return this.subjectPostMessageEvent.asObservable();
    }
}
