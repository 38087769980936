import { Injectable } from '@angular/core';
import { ApplicationsHttpService } from "../../http-services/applications/applications-http.service";
import { BehaviorSubject, map, Observable } from "rxjs";
import {
    TApplication,
    TApplicationData,
    TApplicationFullData, TApplicationTypes,
    TFilters,
    TNewMessage, TSendRateCase, TStatusFilters,
    TTgNotificationSubscribeData,
    TTgNotificationSubscribeStatus,
    TTgNotificationSubscribeStatusGet
} from "../../../models/ApplicationData";
import { TUppdateApplication } from "../../../html-elements/application-details/application-details-form/application-details-form.types";
import { IStandartResponse } from "../../../../../../services/handle-error.service";
import { EnvService } from 'src/app/models/env';

@Injectable({
    providedIn: 'root'
})
export class ApplicationsDataService {
    private _applications: BehaviorSubject<TApplicationData[]> = new BehaviorSubject<TApplicationData[]>(null);
    private _activeApplication: BehaviorSubject<TApplicationData> = new BehaviorSubject<TApplicationData>(null);
    private _archivalChat: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    private _extendedApplication: BehaviorSubject<TApplicationFullData> = new BehaviorSubject<TApplicationFullData>(null);
    private _messageSpinner: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    private _isInaccessibility: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    private _tgNotificationSubscribeData: BehaviorSubject<TTgNotificationSubscribeData> = new BehaviorSubject<TTgNotificationSubscribeData>({
        subscription: 'uninitialized',
        tg_secret: ''
    });

    constructor(
        private applicationsHttpService: ApplicationsHttpService,
        private envService: EnvService
    ) {
    }

    getIsInaccessibility(): Observable<boolean> {
        return this._isInaccessibility.asObservable();
    }

    getMessageSpinner(): Observable<boolean> {
        return this._messageSpinner.asObservable();
    }

    setMessageSpinner(value: boolean): void {
        this._messageSpinner.next(value);
    }

    getExtendedApplication(): Observable<TApplicationFullData> {
        return this._extendedApplication.asObservable();
    }

    setExtendedApplication(value: TApplicationFullData) {
        this._extendedApplication.next(value);
    }

    getActiveApplication(): Observable<TApplicationData> {
        return this._activeApplication.asObservable();
    }

    setActiveApplication(value: TApplicationData): void {
        this._activeApplication.next(null);
        this._activeApplication.next(value);
    }

    getApplications(): Observable<TApplicationData[]> {
        return this._applications.asObservable();
    }

    setApplications(value: TApplicationData[]): void {
        this._applications.next(value);
    }

    getArchivalChat(): Observable<boolean> {
        return this._archivalChat.asObservable();
    }

    setArchivalChat(value: boolean): void {
        this._archivalChat.next(value);
    }

    getTgNotificationSubscribeStatus(): Observable<TTgNotificationSubscribeStatus> {
        return this._tgNotificationSubscribeData.asObservable().pipe(map(
            data => data.subscription
        ));
    }

    setTgNotificationSubscribeStatus(value: boolean): void {
        const status: TTgNotificationSubscribeStatus = value ? 'subscribed' : 'not_subscribed';
        this._tgNotificationSubscribeData.next({
            ...this._tgNotificationSubscribeData.getValue(),
            subscription: status
        });
    }

    loadApplicationAdditionalData(application: TApplicationData): ApplicationsDataService {
        if (!application) {
            return;
        }
        this._extendedApplication.next(null);

        this.applicationsHttpService.getApplicationAdditionalData(application.case_id, 1, true).subscribe(response => {
            if (!response.data) {
                return;
            }
            this.setExtendedApplication({ ...application, ...response.data });
        });
        return this;
    }

    public updateApplicationsHttpData(): ApplicationsDataService {
        this.applicationsHttpService.getApplications(1).subscribe(applications => {
            if (!applications?.data) {
                return;
            }

            if (applications.status >= 400) {
                this._isInaccessibility.next(true)
            }

            this._applications.next(applications.data);
            this._activeApplication.next(applications.data[0]);
        });
        return this;
    }

    public createNewApplication(application: TApplication): Observable<any> {
        let formData: FormData = new FormData();
        for (let field in application) {
            if (typeof application[field] === 'boolean') {
                application[field] = Number(application[field])
            }
            if (field === 'chat_files') {
                application[field].forEach((file, index) => {
                    formData.append(`${field}[${index}]`, file);
                })
            } else {
                formData.append(field, application[field]);
            }

        }
        return this.applicationsHttpService.createApplication(formData);
    }

    public createNewMessage(message: TNewMessage): Observable<any> {
        let formData: FormData = new FormData();
        for (let field in message) {
            if (field === 'chat_files') {
                message[field].forEach((file, index) => {
                    formData.append(`${field}[${index}]`, file);
                })
            } else {
                formData.append(field, message[field]);
            }

        }
        return this.applicationsHttpService.createMessage(formData);
    }

    public setRateCase(data: TSendRateCase): Observable<IStandartResponse<[]>> {
        return this.applicationsHttpService.setRating(data);
    }

    public updateApplication(application: TUppdateApplication): Observable<any> {
        let formData: FormData = new FormData();
        for (let field in application) {
            if (typeof application[field] === 'boolean') {
                application[field] = Number(application[field])
            }
            if (field === 'chat_files') {
                application[field].forEach((file, index) => {
                    formData.append(`${field}[${index}]`, file);
                })
            } else {
                formData.append(field, application[field]);
            }

        }
        return this.applicationsHttpService.updateApplication(formData);
    }

    public loadMoreApplications(page: number, subject: string = '', limit: number = 10, filters: TFilters = 'response_desc', unread: boolean = false, statuses: TStatusFilters = { open: true, closed: true, new: true, awaiting: true }): void {
        this.applicationsHttpService.getApplications(page, subject, limit, filters, unread, statuses).subscribe(applications => {
            let newApplications: TApplicationData[] = applications.data;
            let applicationList: TApplicationData[] = [];
            this.getApplications().subscribe(applications=> {
                applicationList = applications;
                }
            ).unsubscribe();
            if(page != 1){
                this._applications.next([...applicationList, ...newApplications]);
            }
            else{
                this._applications.next(newApplications);
            }
        });
    }

    public getNotifications(): Observable<any> {
        return this.applicationsHttpService.getNotifications();
    }

    public resetNotifications(caseId: number): Observable<any> {
        return this.applicationsHttpService.resetNotifications(caseId);
    }

    public getUserData(): Observable<any> {
        return this.applicationsHttpService.getUserData();
    }

    public changeSubject(id: number, subject: string): void {
        let applicationsList: TApplicationData[] = [];
        this.getApplications().subscribe(applications =>{
            applicationsList = applications;
        }).unsubscribe();
        let index = applicationsList.findIndex(application => application.case_id === id);
        if(typeof  applicationsList[index] !== 'undefined'){
            applicationsList[index].subject = subject;
        }
    }

    public changeStatus(id: number, status: TApplicationTypes): void {
        let applicationsList: TApplicationData[] = this._applications.getValue();
        let activeApplication: TApplicationData = this._activeApplication.getValue();
        if(applicationsList.length){
            let index = applicationsList.findIndex(application => application.case_id == id);
            if(typeof applicationsList[index] !== 'undefined'){
                applicationsList[index].status = status;
                if(activeApplication.case_id == id){
                    activeApplication.status = status;
                    this.setActiveApplication(activeApplication);
                }
            }
        }
    }

    public loadTgNotificationSubscribeStatus(): void {
        this.applicationsHttpService.getTgNotificationSubscribeStatus().subscribe((data: TTgNotificationSubscribeStatusGet) => {
            this._tgNotificationSubscribeData.next(data.data)
        });
    }

    public updateSubscribeTgNotifications(isSubscribe: boolean, case_id?: number): Observable<IStandartResponse<[]>> {
        return this.applicationsHttpService.updateSubscribeTgNotifications(isSubscribe, case_id);
    }

    public openTgLinkBot(caseId?: number | string): void {
        let params: string = `${this.envService.currentAmoUserId}_${this.envService.amoId}_${this._tgNotificationSubscribeData.getValue().tg_secret}`;
        if (caseId) {
            params += `_${caseId}`;
        }
        params = window.btoa(params);
        const url = `https://t.me/sensei_notifications_support_bot?start=${params}`;
        window.open(url, '_blank').focus();
    }
}

