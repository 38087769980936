
export const ElementTypes = {
    JS: 'js',
    CSS: 'css',
    NOTE: 'note',
    TAG: 'tag',
    PROCESS: 'process',
    TASK: 'task',
    ASSISTANT: 'assistant',
    IF: 'if',
    FIELD: 'field',
    FAKE: 'fake',
    LEAD: 'lead',
    QUEUE: 'queueuser',
    SMSCENTER: 'smsc',
    SMSRU: 'smsru',
    START: 'start',
    SIPUNI: 'sipuni',
    GET_DOC: 'getdoc',
    WAIT: 'wait',
    TELEGRAMBOT: 'telegrambot',
    WEBHOOK: 'webhook',
    END: 'end',
    FORM: 'form',
    RESPONSIBLE: 'responsible',
    SALESBOT: 'salesbot',
    MAIL: 'mail',
    STATUS: 'status',
    HYPERSCRIPT: 'hyperscript',
    MANDRILL: 'mandrill',
    B2BFAMILY: 'b2bfamily',
    SPLITTER: 'splitter',
    PRIVATE_WIDGET: 'privatewidget',
    SCRIPT: 'script',
    MULTICONDITION: 'multicondition',
    LIMITER: 'limiter',
    WABABA: 'whatsapp',
    SENSEI_MAILER: 'senseimailer',
    SUPERFIELD: 'superfield',
    CLOUD_STORAGE: 'cloudstorage',
    ROBOCODE: 'robocode'
};


