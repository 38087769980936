import { Injector } from "@angular/core";
import { createCustomElement } from "@angular/elements";

export abstract class BaseAEService {
    readonly componentName: string;

    private parent: Element = document.body;

    constructor(componentName: string, injector: Injector, component: any) {
        this.componentName = componentName;
        this.registor(componentName, component, injector);
    }

    protected registor(componentName: string, component: any, injector: Injector): void {
        if (!customElements.get(componentName)) {
            const customElement = createCustomElement(component, {injector});
            // Register the custom element with the browser.
            customElements.define(componentName, customElement);
        }
    }

    public setParent(node: Element): this {
        this.parent = node;
        return this;
    }

    protected getParent(): Element {
        return this.parent;
    }
}