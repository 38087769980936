import { TLangObject } from "src/app/translate/lang.types";

export const langData: TLangObject = {
    "ru": {
        "error_log_page_front": {
            "header": {
                "text": "История запусков"
            },
            "columns": {
                "0": "Сделка",
                "1": "Название процесса",
                "2": "Дата запуска",
                "3": "Дата последнего действия",
                "4": "Последний элемент",
                "5": "Состояние",
            },
            "statuses": {
                "started": "Запущен",
                "ended": "Завершён",
                "error": "Ошибка",
            },
            "lead_not_found": "Сделка с ID {{id}} не найдена",
            "process_not_found": "Процесс с ID {{id}} не найден",
            "no_data": {
                "message": "К сожалению, сделок с выбранными вами условиями не найдено.",
                "link": "Показать все"
            },
            "selected_checkbox": {
                "all": "Выбрать все",
                "remove_all": "Снфть выделение"
            },
            "column_prefix": {
                "4": "ID"
            }
        }
    },
    "en": {
        "error_log_page_front": {
            "header": {
                "text": "История запусков"
            },
            "columns": {
                "0": "Сделка",
                "1": "Название процесса",
                "2": "Дата запуска",
                "3": "Дата последнего действия",
                "4": "Последний элемент",
                "5": "Состояние",
            },
            "statuses": {
                "started": "Запущен",
                "ended": "Завершён",
                "error": "Ошибка",
            },
            "lead_not_found": "Сделка с ID {{id}} не найдена",
            "process_not_found": "Процесс с ID {{id}} не найден",
            "no_data": {
                "message": "К сожалению, сделок с выбранными вами условиями не найдено.",
                "link": "Показать все"
            },
            "selected_checkbox": {
                "all": "Выбрать все",
                "remove_all": "Снфть выделение"
            },
            "column_prefix": {
                "4": "ID"
            }
        }
    },
    "pt": {
        "error_log_page_front": {
            "header": {
                "text": "История запусков"
            },
            "columns": {
                "0": "Сделка",
                "1": "Название процесса",
                "2": "Дата запуска",
                "3": "Дата последнего действия",
                "4": "Последний элемент",
                "5": "Состояние",
            },
            "statuses": {
                "started": "Запущен",
                "ended": "Завершён",
                "error": "Ошибка",
            },
            "lead_not_found": "Сделка с ID {{id}} не найдена",
            "process_not_found": "Процесс с ID {{id}} не найден",
            "no_data": {
                "message": "К сожалению, сделок с выбранными вами условиями не найдено.",
                "link": "Показать все"
            },
            "selected_checkbox": {
                "all": "Выбрать все",
                "remove_all": "Снфть выделение"
            },
            "column_prefix": {
                "4": "ID"
            }
        }
    },
    "es": {
        "error_log_page_front": {
            "header": {
                "text": "История запусков"
            },
            "columns": {
                "0": "Сделка",
                "1": "Название процесса",
                "2": "Дата запуска",
                "3": "Дата последнего действия",
                "4": "Последний элемент",
                "5": "Состояние",
            },
            "statuses": {
                "started": "Запущен",
                "ended": "Завершён",
                "error": "Ошибка",
            },
            "lead_not_found": "Сделка с ID {{id}} не найдена",
            "process_not_found": "Процесс с ID {{id}} не найден",
            "no_data": {
                "message": "К сожалению, сделок с выбранными вами условиями не найдено.",
                "link": "Показать все"
            },
            "selected_checkbox": {
                "all": "Выбрать все",
                "remove_all": "Снфть выделение"
            },
            "column_prefix": {
                "4": "ID"
            }
        }
    }
}
