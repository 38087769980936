import { LanguageService } from "src/app/translate/language.service";

export type TSelectOptionInit<T extends string = string> = {
    id: string | number;
    value?: T;
    _label?: string;
    label?: string;
    field_type?: string | number;
    entity_type?: string;
    field_code?: string;
    field_enum?: string;
    hidden?: boolean;
    placeholder?: string;
    svg?: any;
    description?: string;
    data_value?: any;
    with_checkbox?: boolean
    groupId?: string;
    link?: string;
}

///////////////////////////////////////////////
//Это был просто объект, но теперь это класс.
//Из-за того, что много где в коде осталась инициализация SelectOption как обычного объекта,
//Добавление любой логики в этот класс нежелательно.
//Если логика необходима, то надо удостовериться, что в нужных местах используются именно экземпляры класса,
//а не объекты с такой же сигнатурой.
export class SelectOption<B extends string = string> {
    id: string | number;
    value: B;
    private _label?: string;
    field_type?: string | number = '';
    entity_type?: string = '';
    field_code?: string = '';
    field_enum?: string = '';
    hidden?: boolean = false;
    placeholder?: string = '';
    svg?: any;
    description?: string;
    data_value?: any;
    with_checkbox?: boolean;
    groupId?: string = '';
    link?: string = '';

    constructor(data: TSelectOptionInit<B>) {
        this.id = data.id;
        this.value = data?.value ?? ('' as B);
        this._label = data._label ?? data.label;

        this.field_type = data?.field_type ?? '';
        this.entity_type = data?.entity_type ?? '';
        this.field_code = data?.field_code ?? '';
        this.field_enum = data?.field_enum ?? '';
        this.hidden = !!data.hidden;
        this.placeholder = data?.placeholder ?? '';
        this.svg = data?.svg;
        this.description = data?.description;
        this.data_value = data?.data_value;
        this.with_checkbox = data?.with_checkbox;
        this.groupId = data?.groupId;
        this.link = data?.link;
    }

    get label() : string {
        return ((this._label !== undefined) ? LanguageService.translate(this._label) : '');
    }

    set label(value: string){
        this._label = value;
    }

    toJSON? = (): TSelectOptionInit => {
        let json: TSelectOptionInit = {
            label: this.label,
            id: this.id,
            value: this.value,
        };

        Object.keys(this).forEach((key) => {
            if(typeof this[key] !== 'undefined' && !['label', '_label', 'toJSON'].includes(key)){
                json[key] = this[key];
            }
        });

        return json;
    }
};
