<div class="promoted-checkbox" [ngClass]="onlyBox ? 'only-box' : ''">
    <svg xmlns="http://www.w3.org/2000/svg" style="display: none" >
        <symbol id="checkmark" width="10" height="8" viewBox="0 0 10 8">
            <path
                d="M1.64763 4.46102L1.64765 4.461L0.982664 4.47063C0.982663 4.47063 0.982662 4.47063 0.982661 4.47063C0.805779 4.65125 0.80578 4.93841 0.982664 5.11903L0.982648 5.11904L0.984504 5.12087L2.9045 7.01439L2.90452 7.01441C3.08789 7.19519 3.38427 7.1952 3.56763 7.0144L3.56763 7.01439L9.0068 1.65022C9.19342 1.47137 9.19837 1.17625 9.01735 0.991401C8.83715 0.807391 8.54078 0.802359 8.3543 0.979916L8.35425 0.979969L8.34452 0.989569L8.3445 0.989552L3.23607 6.02755L1.64763 4.46102Z"
                stroke-width="0.3" />
        </symbol>
        <symbol id="checkmark_amo" width="14" height="13" viewBox="-1 -1 16 15">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M13.3887 0.178399C12.9488 -0.171419 12.2255 0.0141261 11.8032 0.561308L6.01183 7.71722C5.92316 7.82831 5.698 7.86731 5.5825 7.79403L2.01016 5.54031C1.44666 5.1314 0.727998 5.15976 0.407165 5.61713C0.0944983 6.06385 0.197165 6.79185 0.650998 7.34731L4.74483 12.3511C5.08783 12.7695 5.53233 12.9999 5.99666 12.9999C6.53916 12.9999 7.04083 12.6856 7.36983 12.1396L13.5112 1.95822C13.902 1.31058 13.8495 0.545944 13.3887 0.178399Z"/>
        </symbol>

    </svg>

    <svg xmlns="http://www.w3.org/2000/svg">
        <symbol id="line" width="8" height="2" viewBox="0 0 8 3">
            <path stroke-linejoin="round" d="M8 1.99995H0V0.699951H8V1.99995Z" stroke="var(--color-text)" fill="var(--color-text)" stroke-width="0.3"/>
        </symbol>
        <symbol id="line_amo" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line x1="4" y1="10" x2="16" y2="10" stroke="black" stroke-width="2"/>
        </symbol>
    </svg>
    <input [attr.id]="id" type="checkbox" class="promoted-input-checkbox" [checked]="_checked" (change)="onChangeCheckbox($event)" #checkbox/>
    <label [attr.for]="id">
        <svg [ngClass]="line ? 'line' : 'checkmark'">
            <use [attr.xlink:href]="line ? (checkmark === 'amo') ? '#line_amo' : '#line' : (checkmark === 'amo') ? '#checkmark_amo' : '#checkmark'" />
        </svg>
        <div [ngStyle]="{'margin-left': label_text_ml ?? '', 'max-width': maxWidth}" class="label-text">
            @if (label) {
                {{label}}
            } @else {
                <ng-content></ng-content>
            }</div>
    </label>
</div>
