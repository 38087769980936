import { TAmoProcess } from "../processes/data-history-page-processes.types";
import { AMO_FILTER_LAST_ELEMENT } from "../../amo-table/services/filter/amo-table-filter.types";

export type THistoryInstance = {
    id_lead: number,
    id_process: number,
    id_process_instance: number,
    state: string,//"ended",
    time_last_event: number,
    time_start: number,
    current_item_id: number,
}

export type THistoryInstanceResponse = {
    items: THistoryInstance[],
    page_count: number
}

export const LEADS_RESPONSE_MESSAGE_KEY = "get_leads_response";
export const GET_LEADS_MESSAGE_KEY = "get_leads";
export const GET_PROCESSES_LIST_MESSAGE_KEY = "pocesses_list";
export const FRAIME_TABLE_LOAD_MESSAGE_KEY = "fraime_table_load";
export const GET_ID_LEADS_FOR_NAME_MESSAGE_KEY = "get_id_leads_for_name";
export const GET_ID_LEADS_FOR_NAME_RESPONSE_MESSAGE_KEY = "get_id_leads_for_name_response";

export type THistoryInstanceIdsLeadsForNameResponseMessage = {
    key: typeof GET_ID_LEADS_FOR_NAME_RESPONSE_MESSAGE_KEY,
    leads: string[]
}

export type THistoryInstanceLeadsResponseMessage = {
    key: typeof LEADS_RESPONSE_MESSAGE_KEY,
    leads: {[id:number]: string}
}

export type TProcessesListResponseMessage = {
    key: typeof GET_PROCESSES_LIST_MESSAGE_KEY,
    processes: TAmoProcess[]
}

export type TFiltersHistoryPage = {
    limit: number,
    page: number,
    id_lead: string[],
    id_process?: string[],
    status: string,
    [AMO_FILTER_LAST_ELEMENT]?: string
}
