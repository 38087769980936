import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, takeWhile } from 'rxjs';
import { ElementsGrouped } from 'src/app/htmlElements/listGroup/listGroup.types';
import { IndexedBDServiceService } from '../contentServices/IndexedBd/indexed-bdservice.service';
import { SelectOption } from 'src/app/htmlElements/select/select.types';
import { LanguageService } from 'src/app/translate/language.service';
import { SuperFunctionsHttpService, TAccountSuperfunctionData, TAccountSuperfunctionDataFunction, TSuperfunctionDataParameterRows } from '../httpServices/SupeFunctionsHttp.service';
import { IStandartResponse } from '../handle-error.service';
import { FormArray, FormControl } from '@angular/forms';


export type TSuperFunctionsDataInBD = {
    version: number,
    data: ElementsGrouped[]
};

export interface IDataSuperFunctionService {
    setSuperfunctionVersion(version: number): DataSuperFunctionService;
    getSuperFunctionsData(): DataSuperFunctionService;
    getSuperfunctionData(): Observable<ElementsGrouped[]>;
    getSuperFunctionName(id: string): string;
}

@Injectable({
    providedIn: 'root',
})
export class DataSuperFunctionService implements IDataSuperFunctionService {
    private superfunctionVersion: number = 1;

    private superfunctionDataSubject = new BehaviorSubject<ElementsGrouped[]>(null);
    private superFunctionsNames: {[id: string]: string} = {};

    constructor(
        private indexedDBService: IndexedBDServiceService,
        private superFunctionsHttpService: SuperFunctionsHttpService,
        private languageService: LanguageService
    ) {

    }

    getSuperFunctionName(id: string): string {
        if (!this.superFunctionsNames[id]) {
            throw new Error('unknow super function!');
        }
        return this.superFunctionsNames[id];
    }

    public setSuperfunctionVersion(version: number): DataSuperFunctionService {
        this.superfunctionVersion = version;
        return this;
    }

    public getSuperFunctionsData(): DataSuperFunctionService {
        if (typeof this.superfunctionVersion === 'undefined') {
            throw new Error('Need set version from account data');
        }
        this.getSuperFunctionsDataFromIndexedDb();
        return this;
    }

    private setSuperFunctionsDataFromIndexedDb(data: ElementsGrouped[]): DataSuperFunctionService  {
        this.indexedDBService.setItem('super_functions_data', {
            version: this.superfunctionVersion,
            data: data
        });
        return this;
    }

    private getSuperFunctionsDataFromHttp(): DataSuperFunctionService  {
        this.superFunctionsHttpService.getSuperFunctionsData().pipe(takeWhile(value => !!value)).subscribe((res: IStandartResponse<TAccountSuperfunctionData[]>) => {
            if (!!res && !!res.data) {
                this.setSuperfunctionData(res.data);
            }
        });
        return this;
    }


    private getSuperFunctionsDataFromIndexedDb(): DataSuperFunctionService  {
        this.indexedDBService.getItemAsync('super_functions_data').then((data: TSuperFunctionsDataInBD) => {
            if (!data || (!!data && data.version !== this.superfunctionVersion)) {
                return this.getSuperFunctionsDataFromHttp();
            }
            this.setSuperFunctionsOnSubject(data.data);

        });
        return this;
    }

    private setSuperFunctionsOnSubject(data: ElementsGrouped[]): DataSuperFunctionService {
        this.setSetSuperFunctionsNames(data).superfunctionDataSubject.next(data);
        return this;
    }

    private setSetSuperFunctionsNames(data: ElementsGrouped[]): DataSuperFunctionService {
        data.map((group: ElementsGrouped) => {
            group.value.map((element: SelectOption) => {
                this.superFunctionsNames[element.id] = LanguageService.translate(element.label);
            });
        });
        return this;
    }

    public getSuperfunctionData(): Observable<ElementsGrouped[]> {
        return this.superfunctionDataSubject.asObservable();
    }

    private setSuperfunctionData(accountSuperfunctionData: TAccountSuperfunctionData[]): void {
        let superfunctionData: ElementsGrouped[] = [];
        accountSuperfunctionData.forEach((accountSuperfunctionData: TAccountSuperfunctionData, index: number) => {
            let functions: SelectOption[] = [];
            accountSuperfunctionData.functions.forEach((accountSuperfunctionDataFunction: TAccountSuperfunctionDataFunction) => {
                functions.push(new SelectOption({
                    id: accountSuperfunctionDataFunction.code_name,
                    value: '',
                    label: this.languageService.translate('superfunctions.functions.' + accountSuperfunctionDataFunction.code_name + '.name'),
                    description: this.languageService.translate('superfunctions.functions.' + accountSuperfunctionDataFunction.code_name + '.description'),
                    data_value: accountSuperfunctionDataFunction.parameter_rows
                }));
            });
            superfunctionData.push({
                group_name: this.languageService.translate('superfunctions.groups.' + accountSuperfunctionData.code_name + '.name'),
                sort: index,
                value: functions
            })
        });
        this.setSuperFunctionsDataFromIndexedDb(superfunctionData);
        this.setSuperFunctionsOnSubject(superfunctionData);
    }

}
