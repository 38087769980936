import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, retry } from 'rxjs/operators';

import { HandleErrorService, IStandartResponse } from './handle-error.service';

import { EnvService } from './../models/env';
import { Observable } from 'rxjs';
import { AccountDataType } from './data-account.service';

@Injectable({
  providedIn: 'root'
})

export class AccountService {

    constructor(
            private httpClient: HttpClient,
            private HandleErrorService: HandleErrorService,
            private envService: EnvService
    ) { }

    getAccount(process_id?: number ): Observable<IStandartResponse<AccountDataType>> {
        let processId: string = '';
        if (process_id) {
            processId = `?process_id=${process_id}`;
        }
        return this.httpClient.get<any>
            (`${this.envService.serverUrl}/v1/constructor/get/datas${processId}`, this.envService.httpOptions).pipe(catchError(err => this.HandleErrorService.handleError(err)));
    }
}
