<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" class="spiner spinner-centred">
    <path d="M16.7508 7.48569C18.818 7.48569 20.4938 5.80996 20.4938 3.74285C20.4938 1.67573 18.818 0 16.7508 0C14.6836 0 13.0078 1.67573 13.0078 3.74285C13.0078 5.80996 14.6836 7.48569 16.7508 7.48569Z" [style.fill]="fillColor" fill-opacity="0.25"/>
    <path d="M16.7514 31.9997C17.9916 31.9997 18.997 30.9944 18.997 29.7543C18.997 28.5141 17.9916 27.5088 16.7514 27.5088C15.5112 27.5088 14.5059 28.5141 14.5059 29.7543C14.5059 30.9944 15.5112 31.9997 16.7514 31.9997Z" [style.fill]="fillColor" fill-opacity="0.25"/>
    <path d="M7.55253 10.9202C9.41314 10.9202 10.9215 9.41196 10.9215 7.55142C10.9215 5.69088 9.41314 4.18262 7.55253 4.18262C5.69192 4.18262 4.18359 5.69088 4.18359 7.55142C4.18359 9.41196 5.69192 10.9202 7.55253 10.9202Z" [style.fill]="fillColor" fill-opacity="0.25"/>
    <path d="M25.9477 27.8151C26.9813 27.8151 27.8192 26.9773 27.8192 25.9437C27.8192 24.9101 26.9813 24.0723 25.9477 24.0723C24.9141 24.0723 24.0762 24.9101 24.0762 25.9437C24.0762 26.9773 24.9141 27.8151 25.9477 27.8151Z" [style.fill]="fillColor" fill-opacity="0.25"/>
    <path d="M3.74366 19.743C5.39702 19.743 6.73732 18.4027 6.73732 16.7494C6.73732 15.0961 5.39702 13.7559 3.74366 13.7559C2.09031 13.7559 0.75 15.0961 0.75 16.7494C0.75 18.4027 2.09031 19.743 3.74366 19.743Z" [style.fill]="fillColor" fill-opacity="0.25"/>
    <path d="M29.756 18.2443C30.5823 18.2443 31.2522 17.5744 31.2522 16.7481C31.2522 15.9218 30.5823 15.252 29.756 15.252C28.9296 15.252 28.2598 15.9218 28.2598 16.7481C28.2598 17.5744 28.9296 18.2443 29.756 18.2443Z" [style.fill]="fillColor" fill-opacity="0.25"/>
    <path d="M5.70144 24.0942C4.67764 25.1179 4.67764 26.7744 5.70144 27.7981C6.72402 28.8219 8.38298 28.8219 9.40557 27.7981C10.4294 26.7744 10.4294 25.1179 9.40557 24.0942C8.38298 23.0692 6.72524 23.0595 5.70144 24.0942Z" [style.fill]="fillColor" fill-opacity="0.25"/>
    <path d="M25.9464 8.67345C26.5661 8.67345 27.0686 8.17106 27.0686 7.55132C27.0686 6.93159 26.5661 6.4292 25.9464 6.4292C25.3266 6.4292 24.8242 6.93159 24.8242 7.55132C24.8242 8.17106 25.3266 8.67345 25.9464 8.67345Z" [style.fill]="fillColor" fill-opacity="0.25"/>
</svg>
