import { Route } from "@angular/router";

export const BILLING_ROUTING: Route =
    {
        path: 'amo/billing/:amo_account_id',
        loadComponent: () => import('src/app/content/amo/billing/billing.component').then(m => {
            return m.BillingComponent
        }),
        data: { page_state: "billing" },
        children: [
            {
                path: 'main',
                loadComponent: () => import('src/app/content/amo/billing/billing-main/billing-main.component').then(m => {
                    return m.BillingMainComponent
                })
            },
            { path: '**', redirectTo: 'main', pathMatch: 'full' },
            { path: '', redirectTo: 'main', pathMatch: 'full' }
        ]
    };
