import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { throwError } from 'rxjs';
import { ActivatedRoute, Router, RouterStateSnapshot } from '@angular/router';
import { TEnableFeatures } from 'src/app/models/EnableFeatures';
import { LanguageService } from "src/app/translate/language.service";

export type TStandartResponseVersion = {
    widget: string,
    element: string,
    constructor: number
}

export type TStandartResponse = {
    data: any,
    message: string,
    server: string,
    status: number,
    version: TStandartResponseVersion,
    features?: TEnableFeatures,
    calendar_user_rights?: TCalendarUserRights;
}

export type TCalendarUserRights = {
    user_id: number,
    is_amo_admin: number,
    role: 'admin' | 'user' | 'team_lead',
    teamlead_of: number[]
}

export interface IStandartResponse<T> extends TStandartResponse {
    features: TEnableFeatures;
    message: string
    server: string;
    status: number;
    version: TStandartResponseVersion;
    data: T;
    sessions?: IResponseSessions,
    calendar?: {
        user_rights?: TCalendarUserRights,
        client_code: string
    };
    type?: HttpEventType
}

export type IResponseSessions = {
    has_limit: boolean,
    is_limit_spent: boolean
}

@Injectable({
    providedIn: 'root'
})
export class HandleErrorService {

    constructor(private router: Router, private route: ActivatedRoute) {
    }

    public handleError(error: HttpErrorResponse): any {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            // Произошла ошибка на стороне клиента или в сети. Обращайся соответственно.
            console.error('Произошла ошибка:', error.error.message);
            errorMessage = `Произошла ошибка`;
           // return throwError('Error! что-то пошло не так.');
        } else {
            // вернуть наблюдаемое с пользовательским сообщением об ошибке
            if (error.status == 500 && error.url.includes('widget/private') !== true) {
                return throwError(LanguageService.translate('constructor.elements.base.errors.status_500'));
            } else if (error.status == 401) {
                this.router.navigate(['/page401']);
                return throwError('401');
            } else if (error.status == 402) {
                this.router.navigate(['/page402'], { state: error.error });
                return throwError('402');
            } else if (error.status == 403) {
                this.router.navigate(['/page403']);
                return throwError('403');
            } else if (error.status == 404) {
                this.router.navigate(['/page404']);
                return throwError('404');
            } else if (error.status == 420) {
                this.router.navigate(['/amo/billing/' + this.route.snapshot.firstChild.paramMap.get('amo_account_id')]);
            } else {
                return throwError(error.error);
            }
        };
    }
}
