import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { TAmoProcess, THistoryPageProcesses } from './data-history-page-processes.types';
import { IndexedBDServiceService } from 'src/app/services/contentServices/IndexedBd/indexed-bdservice.service';

@Injectable({
    providedIn: 'root'
})
export class DataHistoryPageProcessesService {
    private processesHistoryPageSubject = new BehaviorSubject<THistoryPageProcesses>(undefined);

    constructor(private indexedDBService: IndexedBDServiceService,) { }
    getProcesses(): THistoryPageProcesses {
        return this.processesHistoryPageSubject.getValue();
    }
    
    getProcessesHistoryPage(): Observable<THistoryPageProcesses> {
        return this.processesHistoryPageSubject.asObservable();;
    }

    setProcessesHistoryPage(processesList: TAmoProcess[]): void{
        const value: THistoryPageProcesses = processesList.reduce((acc, curr) => {
            acc[curr.id] = curr.name;
            return acc;
        }, {});
        this.indexedDBService.setItem('amo_process_list', processesList);
        this.processesHistoryPageSubject.next(value);
    }

    getProcessesHistoryInDB(): void {
        this.indexedDBService.getItemAsync('amo_process_list').then((processList: TAmoProcess[]) => {
            !!processList && this.setProcessesHistoryPage(processList);
        });
    }
}
