import { Component, Input } from '@angular/core';
import { SupportCrossComponent } from "../../images/cross/support-cross.component";
import { LanguageModule } from "../../../../../translate/language.module";


@Component({
    selector: 'app-error-modal',
    standalone: true,
    imports: [SupportCrossComponent, LanguageModule],
    templateUrl: './error-modal.component.html',
    styleUrl: './error-modal.component.scss'
})
export class ErrorModalComponent {
    public upperText: string = 'support_chat.errors.upload_error.header';
    @Input() lowerText: string = '';

}
