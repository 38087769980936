import { Injectable, Injector, OnDestroy } from '@angular/core';
import { NgElement, WithProperties } from "@angular/elements";
import { ErrorModalComponent } from "./error-modal.component";
import { BaseAEService } from "../../../../../helpers/BaseAEService";

@Injectable({
    providedIn: 'root'
})
export class ErrorModalService extends BaseAEService implements OnDestroy {
    private popupEl: NgElement & WithProperties<ErrorModalComponent>
    constructor(private injector: Injector) {
        super('app-error-modal', injector, ErrorModalComponent)
    }

    show(urlPathname: string): void {
        if (!!this.popupEl) {
            return;
        }

        this.popupEl = document.createElement(this.componentName) as any;
        let PathPart = urlPathname.split('/')[3];

        if (PathPart == 'post-message') {
            this.popupEl.lowerText = 'support_chat.errors.upload_error.message_error';
        }

        else if (PathPart == 'create-case') {
            this.popupEl.lowerText = 'support_chat.errors.upload_error.create_case_error';
        }

        this.popupEl.addEventListener('closeModal', (event: CustomEvent): void => {
            this.hide();
        });
        this.popupEl.addEventListener('click', (event: PointerEvent): void => {
            event.stopPropagation();
            this.hide();
        });

        document.body.appendChild(this.popupEl);
    }

    hide(): void {
        if (!this.popupEl) {
            return;
        }
        document.body.removeChild(this.popupEl);
        this.popupEl = undefined;
    }

    ngOnDestroy(): void {
        this.hide();
    }
}
